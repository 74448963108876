import { Card, Checkbox, Col, DateField, EditButton, Icons, Row, Space, Tag, Typography, useDrawerForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, useShow } from '@pankod/refine-core'
import { UserAddresses } from 'components/orders/user_addresses'
import { definitions } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { IssueDrawer } from './drawer'
import { CLIENT_STATUSES_MAPPER, ISSUE_SUB_TYPES_MAPPER, ISSUE_TYPES_MAPPER, ISSUE_STATUS_MAPPER } from 'utility/mapper'
import { ClientStatusIdTypes, IssueSubTypeIdTypes, IssueTypeIdTypes, IssueStatusIdTypes } from 'types/types'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DELIVERED_STATUS, ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SHIPPED_STATUS, capitalizeFirstLetter, supabaseClient } from 'utility'
import { IssuesFilesList } from 'pages/issues_files'
import { IssueHistory } from './history'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'

type IssuesContextProps = { refetch?: boolean, setRefetch?: Dispatch<SetStateAction<boolean>> }
export const IssuesContext = createContext<IssuesContextProps>({})

export const IssueShow: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation()
  const [refetch, setRefetch] = useState(false)

  const [requestedDate, setRequestedDate] = useState<string>()
  const [processedDate, setProcessedDate] = useState<string>()
  const [supplementDate, setSupplementDate] = useState<string>()
  const [shippedDate, setShippedDate] = useState<string>()
  const [deliveredDate, setDeliveredDate] = useState<string>()

  const {
    drawerProps,
    formProps,
    show,
    close
  } = useDrawerForm<definitions['issues']>({
    action: 'edit',
    resource: 'issues',
    redirect: false
  })

  const issuesFields = `id, order_id, issue_type_id, issue_sub_type_id, client_status_id, issue_status_id, reason, notes, notes_marketplace,
    request_date, shipping_method, shipper, tracking_id, issue_deadline, delivery_deadline, created_at, updated_at, deleted, is_opened, is_completed`

  const addressFields = 'id, email, name, surnames, phone_number, nif, additional_info, is_company, full_address'

  const { queryResult } = useShow<definitions['issues'] & { 'order_id': definitions['Order'], 'items': definitions['items'][], 'audit_order': definitions['audit_orders'][] }>({
    resource: 'issues',
    metaData: {
      select: `${issuesFields}, order_id!inner(*, user(id, name, email, created_at,
        orders!user(id, total, created_at, status_id, syniva_price, shipping_price, shipping_insurance_price, custom_order_price, wallet_amount_used,
        supplements(supplement), items(shipping_price_to_madrid), marketplace_id(id, shipment_price, free_shipment_price))),
        shipping_address(${addressFields}, island(boat_extra_cost, plane_extra_cost)), billing_address(${addressFields}), status(name)), items(id, name)`
    }
  })

  useEffect(() => {
    if (refetch) queryResult.refetch()
    setRefetch(false)
  }, [refetch])

  useEffect(() => {
    const loadData = async () => {
      const { data } = await supabaseClient.from<definitions['audit_orders']>('audit_orders').select('*').order('created_at', { ascending: false }).eq('resource_id', order.id)
      const { data: supData } = await supabaseClient.from<definitions['supplements']>('supplements').select('id, created_at')
        .eq('order_id', order.id)
        .eq('volumetric', true)
        .eq('deleted', false)
        .single()

      if (supData?.created_at) {
        setSupplementDate(supData.created_at)
      }

      if (queryResult.data) {
        queryResult.data.data.audit_order = data || []
        setRequestedDate(data?.find(ao => (ao.record as definitions['orders']).status_id === ORDER_STATUS_ID_REQUESTED)?.created_at)
        setProcessedDate(data?.find(ao => (ao.record as definitions['orders']).status_id === PROCESSED_STATUS)?.created_at)
        // setSupplementDate(data?.find(ao => (ao.record as definitions['orders']).status_id === SUPPLEMENT_STATUS)?.created_at)
        setShippedDate(data?.find(ao => (ao.record as definitions['orders']).status_id === SHIPPED_STATUS)?.created_at)
        setDeliveredDate(data?.find(ao => (ao.record as definitions['orders']).status_id === DELIVERED_STATUS)?.created_at)
      }
    }

    if (queryResult.data?.data.order_id.id) {
      loadData()
    }
  }, [queryResult.data])

  const { data } = queryResult
  const issue = data?.data
  if (!issue) return null

  const { order_id: order } = issue
  if (!order) return null

  console.log(order)

  return (
    <IssuesContext.Provider value={{ refetch, setRefetch }}>
      <Row gutter={[15, 15]}>
        <Col xs={24}>
          <Card bordered={false} style={{ marginBottom: 15 }}>
            <Space
              direction="horizontal"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography.Text style={{ fontWeight: 600, fontSize: '22px' }}>
                <a href={`/orders/show/${order.id}`} target='_blank' rel='noreferrer'>
                  <Icons.LinkOutlined />
                  {' '}
                  {t('issues.show.order_id', { order_id: order.id })}
                </a>
                <Tag style={{ marginLeft: '2rem', padding: 5, fontSize: 16 }} color={issue.is_opened ? 'red' : 'green'}>
                  {t(`issues.issue_status.${issue.is_opened ? 'open' : 'close'}`)}
                </Tag>
              </Typography.Text>
              <EditButton onClick={() => show()} />
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col xs={24} xl={12}>
          <UserAddresses order={order} canEdit={false} />
        </Col>
        <Col xs={24} xl={12}>
          <Row gutter={[15, 15]} style={{ height: '100%' }}>
            <Col xs={24}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Space direction="horizontal" align='start'>
                  <Col>
                    <Typography>
                      <b>{`${t('issues.fields.syniva_status')}: `}</b>
                      {capitalizeFirstLetter(order.status.name)}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.issue_type_id')}: `}</b>
                      {t(`issues.issue_types.${issue.issue_type_id ? ISSUE_TYPES_MAPPER[issue.issue_type_id as IssueTypeIdTypes] : ''}`)}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.issue_sub_type_id')}: `}</b>
                      {issue.issue_sub_type_id ? t(`issues.issue_sub_types.${issue.issue_sub_type_id ? ISSUE_SUB_TYPES_MAPPER[issue.issue_sub_type_id as IssueSubTypeIdTypes] : ''}`) : ''}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.client_status_id')}: `}</b>
                      {issue.client_status_id ? t(`issues.client_statuses.${issue.client_status_id ? CLIENT_STATUSES_MAPPER[issue.client_status_id as ClientStatusIdTypes] : ''}`) : ''}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.issue_status_id')}: `}</b>
                      {issue.issue_status_id ? t(`issues.issue_statuses.${issue.issue_status_id ? ISSUE_STATUS_MAPPER[issue.issue_status_id as IssueStatusIdTypes] : ''}`) : ''}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.is_completed')}: `}</b>
                      <Checkbox checked={issue.is_completed} disabled={true} />
                    </Typography>
                  </Col>
                  <Col>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      <b>{`${t('issues.fields.notes_marketplace')}: `}</b>
                      {issue.notes_marketplace}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.involved_products')}: `}</b>
                      <ul style={{ marginBottom: 0 }}>
                        {queryResult.data?.data?.items.map((item) => <li key={`${item.id}-${item.name}`}>{item.name}</li>)}
                      </ul>
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.reason')}: `}</b>
                      {issue.reason}
                    </Typography>
                    <Typography>
                      <b>{`${t('issues.fields.delivery_deadline')}: `}</b>
                      {issue.delivery_deadline && <DateField value={issue.delivery_deadline} format={DEFAULT_DATE_FORMAT} />}
                    </Typography>
                  </Col>
                </Space>
              </Card>
            </Col>
            <Col xs={24}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  style={{
                    width: '100%'
                  }}
                >
                  <Typography>
                    <b>{`${t('issues.fields.shipping_method')}: `}</b>
                    {issue.shipping_method}
                  </Typography>
                  <Typography>
                    <b>{`${t('issues.fields.shipper')}: `}</b>
                    {issue.shipper}
                  </Typography>
                  <Typography>
                    <b>{`${t('issues.fields.tracking_id')}: `}</b>
                    {issue.tracking_id}
                  </Typography>
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={12}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <Typography>
                  <b>{`${t('issues.fields.requested_date')}: `}</b>
                  {requestedDate && <DateField value={requestedDate} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.processed_date')}: `}</b>
                  {processedDate && <DateField value={processedDate} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.supplement_date')}: `}</b>
                  {supplementDate && <DateField value={supplementDate} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.shipped_date')}: `}</b>
                  {shippedDate && <DateField value={shippedDate} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.delivered_date')}: `}</b>
                  {deliveredDate && <DateField value={deliveredDate} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.issue_deadline')}: `}</b>
                  {issue.issue_deadline && <DateField value={issue.issue_deadline} format={DEFAULT_DATE_FORMAT} />}
                </Typography>
                <Typography>
                  <b>{`${t('issues.fields.request_date')}: `}</b>
                  {issue.request_date && <DateField value={issue.request_date} format={DEFAULT_DATE_TIME_FORMAT} />}
                </Typography>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  <b>{`${t('issues.fields.notes')}: `}</b>
                  {issue.notes}
                </Typography>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xs={12}>
          <Card bordered={false} style={{ maxHeight: '600px', height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <IssueHistory issue={issue} field="client_status_id" />
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xs={12}>
          <Card bordered={false} style={{ maxHeight: '600px', height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <IssueHistory issue={issue} field="issue_status_id" />
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false} style={{ maxHeight: '600px', height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <IssueHistory issue={issue} />
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Space
                direction="vertical"
                style={{
                  width: '100%'
                }}
              >
                <IssuesFilesList issue={issue} />
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>

      <IssueDrawer
        drawerProps={drawerProps}
        formProps={formProps}
        close={close}
        isCreate={false}
        orderId={order.id}
      />
    </IssuesContext.Provider>
  )
}
