import { Order, definitions } from 'interfaces'
import { ADMITTED_STATUS, DEFAULT_IVA, ECONOMY_SHIPMENT_METHOD_ID, MAX_IGIC_VALUE, ORDER_STATUS_ID_PENDING_PAYMENT, PENALIZATION_STATUS, SHIPPING_INSURANCE } from './constants'
import { currencyValue as round } from './currency'
import { supabaseClient } from './supabaseClient'
import { FormProps } from '@pankod/refine-antd'
import { calcIgicPrice } from './ivaCalcs'
import { addTransportSupplementOrRefund, calculateOrderPrices } from './supabaseApi'

export const subtotal = (items: definitions['items'][] | null): number => {
  if (items && items.length > 0) {
    return round(
      items
        .map((i) => i.price * i.quantity)
        .reduce((partialSum, a) => partialSum + a, 0)
    )
  }
  return 0
}

export const savings = (items: definitions['items'][] | null, iva?: number): number => {
  if (items && items.length > 0) {
    return round(subtotal(items) - synivaPrice(items))
  }
  return 0
}

export const synivaPrice = (items: definitions['items'][] | null): number => {
  if (items && items.length > 0) {
    return items.reduce((acc, currVal) => {
      return acc + round((currVal.price * currVal.quantity) / ((((currVal?.iva !== undefined && currVal?.iva !== null) ? currVal?.iva : DEFAULT_IVA) / 100) + 1))
    }, 0)
  }
  return 0
}

export const total = (
  items: definitions['items'][] | null,
  igic: number,
  shippingInsurance: number,
  shippingPrice: number,
  shipMode: boolean = false,
  discount: number = 0,
  wallet: number = 0
) => {
  if (!shipMode) {
    return round(
      synivaPrice(items) +
      igic +
      shippingInsurance +
      shippingPrice +
      (-discount) -
      wallet
    )
  } else {
    return round(
      subtotal(items) +
      igic +
      shippingInsurance +
      shippingPrice +
      (-discount) -
      wallet
    )
  }
}

export const shippingInsurance = (items: definitions['items'][] | null, shippingInsurancePercentage: number | string, shipMode: boolean = false) => {
  let percent: number = 2
  if (!Number.isNaN(Number(shippingInsurancePercentage))) {
    percent = Number(shippingInsurancePercentage)
    const price = (!shipMode) ? synivaPrice(items) : subtotal(items)
    if (!shipMode) {
      return round((price * percent) / 100)
    } else {
      return round((price * percent) / 100)
    }
  } else {
    const { ranges } = JSON.parse(shippingInsurancePercentage as string)

    const price = (!shipMode) ? synivaPrice(items) : subtotal(items)
    if (!shipMode) {
      percent = ranges?.find((range: any) => range.min <= price && range.max > price)?.value
      return round((price * percent) / 100)
    } else {
      percent = ranges?.find((range: any) => range.min <= price && range.max > price)?.value
      return round((price * percent) / 100)
    }
  }
}

export const igic = (items: definitions['items'][] | null, igic: number, lowValueLimit: number, shipMode: boolean = false) => {
  if (!shipMode) {
    return round(synivaPrice(items) < lowValueLimit ? 0 : (synivaPrice(items) * igic) / 100)
  } else {
    return round(subtotal(items) < lowValueLimit ? 0 : (subtotal(items) * igic) / 100)
  }
}

// When need calcs using an item
export const calculateItemSups = async (formProps: FormProps, order: Order, orderId: number, price: number, iva: number, quantity: number, supplementType: string | null, refetchSup?: Function, replace?: boolean) => {
  const { data: initialOrder } = await supabaseClient.from('audit_orders')
    .select('id, record, resource_id')
    .eq('resource_id', orderId)
    .eq('operation', 'INSERT')
    .single()

  const { data: insurancePercentage } = await supabaseClient.from('variables').select('value').eq('name', SHIPPING_INSURANCE).single()

  const shipMode = order.shipping_method?.shipMode || false

  const initialIgic = initialOrder?.record?.igic || 0
  const orderMarketplace = order.marketplace_id as any || null
  const initialIgicPercent = (order.marketplace_id.id === 0) ? ((formProps?.initialValues?.has_accessories === true) ? 3 : 7) : orderMarketplace?.igic_types?.value || 7
  const initialSynivaPrice = initialOrder?.record?.syniva_price || 0
  const initialShippingInsurance = initialOrder?.record?.shipping_insurance_price || 0

  const { data: itemsData } = await supabaseClient.from('items')
    .select('id, price, iva, quantity, syniva_price, disabled, tariff_price, igic_price, igic_percent')
    .eq('order_id', orderId).eq('deleted', false).eq('disabled', false)

  const tariffTotal = itemsData?.map((i) => i.tariff_price || 0).reduce((a, b) => a + b, 0)
  const itemsSynivaPrice = Number(itemsData?.map((i) => i.syniva_price * i.quantity || 0).reduce((a, b) => a + b, 0).toFixed(2) || 0)
  const itemsSubtotalPrice = Number(itemsData?.map((i) => i.price * i.quantity || 0).reduce((a, b) => a + b, 0).toFixed(2) || 0)
  // FIXME: Fixed using date, best try to set the order structure with a migration
  let insurancePrice = shippingInsurance(itemsData, insurancePercentage.value, shipMode)
  const deployDate = new Date('2024-03-06T15:00:00.00000+00:00')
  const createdDate = new Date(order.created_at)
  if (createdDate < deployDate) {
    insurancePrice = shippingInsurance(itemsData, 2, shipMode)
  }

  const initialIvaItem = order.marketplace_id.iva_percent || 21
  let priceChangeDetected = false

  // Process items igic Value
  if (itemsData) {
    for await (const i of itemsData) {
      await supabaseClient.from('items')
        .update({
          igic_percent: (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent,
          igic_price: calcIgicPrice(i.quantity, (shipMode) ? i.price : i.syniva_price, (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent)
        }).eq('id', i.id)
    }
  }

  let igicTotal = (itemsSynivaPrice > MAX_IGIC_VALUE) ? itemsData?.map((i) => calcIgicPrice(i.quantity, (shipMode) ? i.price : i.syniva_price, (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent) || 0).reduce((a, b) => a + b, 0) || 0 : 0
  if (shipMode) igicTotal = (itemsSubtotalPrice > MAX_IGIC_VALUE) ? itemsData?.map((i) => calcIgicPrice(i.quantity, (shipMode) ? i.price : i.syniva_price, (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent) || 0).reduce((a, b) => a + b, 0) || 0 : 0

  await calculateOrderPrices(orderId)

  // Create igic suplement
  let { data: igicSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
    .eq('order_id', orderId).eq('deleted', false).eq('description', 'igic')

  const currentIgicSup = igicSup?.find((s) => s.email_sent_at === null && s.parent === null)
  if (currentIgicSup) {
    igicSup = igicSup?.filter((s) => s.id !== currentIgicSup.id) || null
  }
  const sentIgicSups = Number(igicSup?.filter((s) => s.email_sent_at !== null || parent !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

  const valueIgic = Number((igicTotal - initialIgic) - sentIgicSups)

  // console.log('si', value, igicTotal, initialIgic, sentSups)

  if (currentIgicSup) {
    await supabaseClient.from('supplements')
      .update({
        order_id: orderId,
        description: 'igic',
        supplement: valueIgic.toFixed(2),
        status_id: (valueIgic > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (valueIgic === 0)
      })
      .eq('id', currentIgicSup.id)
  } else {
    await supabaseClient.from('supplements')
      .insert({
        order_id: orderId,
        description: 'igic',
        supplement: valueIgic.toFixed(2),
        status_id: (valueIgic > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (valueIgic === 0)
      })
  }
  if (tariffTotal > 0) {
    // Create tariff suplement
    let { data: tariffSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
      .eq('order_id', orderId).eq('deleted', false).eq('description', 'tariff')

    const currentTariffSup = tariffSup?.find((s) => s.email_sent_at === null && s.parent === null)
    if (currentTariffSup) {
      tariffSup = tariffSup?.filter((s) => s.id !== currentTariffSup.id) || null
    }
    const sentSups = Number(tariffSup?.filter((s) => s.email_sent_at !== null || parent !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

    const value = Number(tariffTotal - sentSups)

    // console.log('st', value, tariffTotal, sentSups)

    if (currentTariffSup) {
      await supabaseClient.from('supplements')
        .update({
          order_id: orderId,
          description: 'tariff',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
        .eq('id', currentTariffSup.id)
    } else {
      await supabaseClient.from('supplements')
        .insert({
          order_id: orderId,
          description: 'tariff',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
    }
  }
  if (formProps?.initialValues?.price !== price || formProps?.initialValues?.quantity !== quantity) {
    priceChangeDetected = true
    // Create priceChange suplement
    let { data: priceChangeSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
      .eq('order_id', orderId).eq('deleted', false).eq('description', 'priceChange')

    const currentPriceChangeSup = priceChangeSup?.find((s) => s.email_sent_at === null && s.parent === null)
    if (currentPriceChangeSup && currentPriceChangeSup.length > 0) {
      priceChangeSup = priceChangeSup?.filter((s) => s.id !== currentPriceChangeSup.id) || null
    }
    const sentSups = Number(priceChangeSup?.filter((s) => s.email_sent_at !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

    // All items price but not the current using the original iva
    const itemsPrice = Number(itemsData?.map((i) => {
      // console.log(i.id, '-> ShipMode', (i.price / ((i.iva / 100) + 1)))
      // console.log(i.id, '-> NoShipMode', i.price)
      if (!shipMode) return ((i.price * i.quantity) / ((initialIvaItem / 100) + 1)) || 0
      else return (i.price * i.quantity) || 0
    }).reduce((a, b) => a + b, 0).toFixed(2) || 0)

    const newItemsPrice = itemsPrice
    const oldItemsPrice = initialSynivaPrice

    const value = Number(((newItemsPrice - oldItemsPrice) - sentSups).toFixed(2))

    // console.log('spc', value, newItemsPrice, oldItemsPrice, sentSups)

    if (currentPriceChangeSup) {
      await supabaseClient.from('supplements')
        .update({
          order_id: orderId,
          description: 'priceChange',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
        .eq('id', currentPriceChangeSup.id)
    } else {
      await supabaseClient.from('supplements')
        .insert({
          order_id: orderId,
          description: 'priceChange',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
    }
  }
  if ((formProps?.initialValues?.iva !== iva || priceChangeDetected) && !shipMode) {
    // Create reducedIva suplement
    let { data: reducedIvaSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
      .eq('order_id', orderId).eq('deleted', false).eq('description', `${(supplementType !== null) ? supplementType : 'reducedIva'}`)

    const { data: allReducedIvaSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
      .eq('order_id', orderId).eq('deleted', false).ilike('description', '%reduced%')

    const currentReducedIVASup = reducedIvaSup?.find((s) => s.email_sent_at === null && s.parent === null)
    if (currentReducedIVASup) {
      reducedIvaSup = reducedIvaSup?.filter((s) => s.id !== currentReducedIVASup.id) || null
    }
    const sentSups = Number(allReducedIvaSup?.filter((s) => s.email_sent_at !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)
    let noSentSup = Number(allReducedIvaSup?.filter((s) => s.email_sent_at === null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)
    if (currentReducedIVASup?.id) noSentSup = Number(allReducedIvaSup?.filter((s) => s.email_sent_at === null && s.id !== currentReducedIVASup.id).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

    const oldItemsPrice = Number(itemsData?.map((i) => {
      // console.log(i.id, (i.price / ((i.iva / 100) + 1)))
      return ((i.price * i.quantity) / ((initialIvaItem / 100) + 1))
    }).reduce((a, b) => a + b, 0).toFixed(2) || 0)
    const newItemsPrice = Number(itemsData?.map((i) => {
      // console.log(i.id, (i.price / ((i.iva / 100) + 1)))
      return ((i.price * i.quantity) / ((i.iva / 100) + 1))
    }).reduce((a, b) => a + b, 0).toFixed(2) || 0)

    const value = Number((newItemsPrice - oldItemsPrice) - noSentSup - sentSups)

    // console.log('sri', value, newItemsPrice, oldItemsPrice, noSentSup, sentSups)

    if (currentReducedIVASup) {
      await supabaseClient.from('supplements')
        .update({
          order_id: orderId,
          description: (supplementType !== null) ? supplementType : 'reducedIva',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
        .eq('id', currentReducedIVASup.id)
    } else {
      await supabaseClient.from('supplements')
        .insert({
          order_id: orderId,
          description: (supplementType !== null) ? supplementType : 'reducedIva',
          supplement: value.toFixed(2),
          status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
          volumetric: false,
          deleted: (value === 0)
        })
    }
  }

  // Create shipping insurance supplement
  let { data: shippingInsuranceSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
    .eq('order_id', orderId).eq('deleted', false).eq('description', 'shippingInsurancePrice')

  const currentShippingInsuranceSup = shippingInsuranceSup?.find((s) => s.email_sent_at === null && s.parent === null)
  if (currentShippingInsuranceSup) {
    shippingInsuranceSup = shippingInsuranceSup?.filter((s) => s.id !== currentShippingInsuranceSup.id) || null
  }
  const sentSups = Number(shippingInsuranceSup?.filter((s) => s.email_sent_at !== null || parent !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

  const value = Number((insurancePrice - initialShippingInsurance) - sentSups)

  // console.log('sip', value, insurancePrice, initialShippingInsurance, sentSups)

  if (currentShippingInsuranceSup) {
    await supabaseClient.from('supplements')
      .update({
        order_id: orderId,
        description: 'shippingInsurancePrice',
        supplement: value.toFixed(2),
        status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (value === 0)
      })
      .eq('id', currentShippingInsuranceSup.id)
  } else {
    await supabaseClient.from('supplements')
      .insert({
        order_id: orderId,
        description: 'shippingInsurancePrice',
        supplement: value.toFixed(2),
        status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (value === 0)
      })
  }

  // Check if need puchase_conditions applications
  if (shipMode && order.status_id !== PENALIZATION_STATUS) {
    const { data: currentOrder } = await supabaseClient.from('orders').select('id, syniva_price, shipping_price, purchase_conditions').eq('id', orderId).single()
    const purchaseConditions = currentOrder?.purchase_conditions || null
    const shippingPriceToAdd = parseFloat(purchaseConditions.shipment_method_price)

    if (purchaseConditions && purchaseConditions.free_vol && purchaseConditions.free_vol === 'true') {
      const { data: transportSupsData } = await supabaseClient.from('supplements')
        .select('id, supplement, description')
        .eq('order_id', orderId)
        .eq('deleted', false)
        .or(`supplement.eq.${shippingPriceToAdd}, supplement.eq.${-shippingPriceToAdd}`)
        .or('description.eq.refundTransport, description.eq.transportSupplement')

      const totalSupsData = transportSupsData?.map((x: any) => x.supplement).reduce((a: number, b: number) => a + b, 0) || 0

      if (purchaseConditions.free_vol_price_min) {
        if (parseFloat(purchaseConditions.free_vol_price_min) < currentOrder.syniva_price && initialSynivaPrice < parseFloat(purchaseConditions.free_vol_price_min)) {
          // console.log(1, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
          if (totalSupsData === 0) {
            // console.log('A')
            await addTransportSupplementOrRefund(orderId, 'refundTransport', ADMITTED_STATUS, -purchaseConditions.shipment_method_price)
          } else {
            // console.log('B')
          }
        } else if (parseFloat(purchaseConditions.free_vol_price_min) < initialSynivaPrice && currentOrder.syniva_price > parseFloat(purchaseConditions.free_vol_price_min)) {
          // console.log(3, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
          if (totalSupsData === 0) {
            // console.log('A')
          } else {
            // console.log('B')
            await addTransportSupplementOrRefund(orderId, 'refundTransport', ADMITTED_STATUS, -purchaseConditions.shipment_method_price)
          }
        } else if (parseFloat(purchaseConditions.free_vol_price_min) < initialSynivaPrice && currentOrder.syniva_price < parseFloat(purchaseConditions.free_vol_price_min)) {
          // console.log(2, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
          if (totalSupsData === 0) {
            // console.log('A')
            await addTransportSupplementOrRefund(orderId, 'transportSupplement', (purchaseConditions.shipment_method_price > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS, purchaseConditions.shipment_method_price)
          } else {
            // console.log('B')
          }
        } else if (parseFloat(purchaseConditions.free_vol_price_min) > currentOrder.syniva_price && initialSynivaPrice < parseFloat(purchaseConditions.free_vol_price_min)) {
          // console.log(4, currentOrder.shipping_price, totalSupsData, purchaseConditions.shipping_price, purchaseConditions.shipment_method_price, initialSynivaPrice, currentOrder.syniva_price)
          if (totalSupsData === 0) {
            // console.log('A')
          } else {
            await addTransportSupplementOrRefund(orderId, 'transportSupplement', (purchaseConditions.shipment_method_price > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS, purchaseConditions.shipment_method_price)
            // console.log('B')
          }
        }
      }
    }
  }

  if (refetchSup) refetchSup()
}

// When need calcs using a complete order
export const calculateOrderSups = async (order: any) => {
  const { data: initialOrder } = await supabaseClient.from('audit_orders')
    .select('id, record, resource_id')
    .eq('resource_id', order.id)
    .eq('operation', 'INSERT')
    .single()

  const { data: insurancePercentage } = await supabaseClient.from('variables').select('value').eq('name', SHIPPING_INSURANCE).single()

  const shipMode = order.shipping_method_id === ECONOMY_SHIPMENT_METHOD_ID

  const itemsSynivaPrice = Number(order.items?.map((i: any) => i.syniva_price * i.quantity || 0).reduce((a: number, b: number) => a + b, 0).toFixed(2) || 0)
  const itemsSubtotalPrice = Number(order.items?.map((i: any) => i.price * i.quantity || 0).reduce((a: number, b: number) => a + b, 0).toFixed(2) || 0)

  // ---------------------------------------------------------------------------------------
  // Create shipping insurance supplement
  // ---------------------------------------------------------------------------------------
  const initialShippingInsurance = initialOrder?.record?.shipping_insurance_price || 0
  let insurancePrice = shippingInsurance(order.items || [], insurancePercentage.value, shipMode)
  const deployDate = new Date('2024-03-06T15:00:00.00000+00:00')
  const createdDate = new Date(order.created_at)
  if (createdDate < deployDate) {
    insurancePrice = shippingInsurance(order.items || [], 2, shipMode)
  }

  let { data: shippingInsuranceSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
    .eq('order_id', order.id).eq('deleted', false).eq('description', 'shippingInsurancePrice')

  const currentShippingInsuranceSup = shippingInsuranceSup?.find((s) => s.email_sent_at === null && s.parent === null)
  if (currentShippingInsuranceSup) {
    shippingInsuranceSup = shippingInsuranceSup?.filter((s) => s.id !== currentShippingInsuranceSup.id) || null
  }
  const sentSups = Number(shippingInsuranceSup?.filter((s) => s.email_sent_at !== null || parent !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

  const value = Number((insurancePrice - initialShippingInsurance) - sentSups)

  // console.log('sip', value, insurancePrice, initialShippingInsurance, sentSups)

  if (currentShippingInsuranceSup) {
    await supabaseClient.from('supplements')
      .update({
        order_id: order.id,
        description: 'shippingInsurancePrice',
        supplement: value.toFixed(2),
        status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (value === 0)
      })
      .eq('id', currentShippingInsuranceSup.id)
  } else {
    await supabaseClient.from('supplements')
      .insert({
        order_id: order.id,
        description: 'shippingInsurancePrice',
        supplement: value.toFixed(2),
        status_id: (value > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (value === 0)
      })
  }

  // ---------------------------------------------------------------------------------------
  // Create Igic supplement
  // ---------------------------------------------------------------------------------------

  const igicTypeValue = (order.marketplace_id as any).igic_types?.value
  const hasAccesories = order.items?.some((i: any) => i.has_accessories)

  const initialIgic = initialOrder?.record?.igic || 0
  const initialIgicPercent = (order.marketplace_id.id === 0) ? ((hasAccesories === true) ? 3 : 7) : igicTypeValue || 7

  let igicTotal = (itemsSynivaPrice > MAX_IGIC_VALUE) ? order.items?.map((i: any) => calcIgicPrice(i.quantity, (shipMode) ? i.price : i.syniva_price, (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent) || 0).reduce((a: number, b: number) => a + b, 0) || 0 : 0
  if (shipMode) igicTotal = (itemsSubtotalPrice > MAX_IGIC_VALUE) ? order.items?.map((i: any) => calcIgicPrice(i.quantity, (shipMode) ? i.price : i.syniva_price, (i.igic_percent === 0) ? initialIgicPercent : i.igic_percent) || 0).reduce((a: number, b: number) => a + b, 0) || 0 : 0

  let { data: igicSup } = await supabaseClient.from('supplements').select('id, supplement, email_sent_at, parent')
    .eq('order_id', order.id).eq('deleted', false).eq('description', 'igic')

  const currentIgicSup = igicSup?.find((s) => s.email_sent_at === null && s.parent === null)
  if (currentIgicSup) {
    igicSup = igicSup?.filter((s) => s.id !== currentIgicSup.id) || null
  }
  const sentIgicSups = Number(igicSup?.filter((s) => s.email_sent_at !== null || parent !== null).map((s) => s.supplement).reduce((a, b) => a + b, 0).toFixed(2) || 0)

  const valueIgic = Number((igicTotal - initialIgic) - sentIgicSups)

  // console.log('sigic', value, igicTotal, initialIgic, sentSups)

  if (currentIgicSup) {
    await supabaseClient.from('supplements')
      .update({
        order_id: order.id,
        description: 'igic',
        supplement: valueIgic.toFixed(2),
        status_id: (valueIgic > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (valueIgic === 0)
      })
      .eq('id', currentIgicSup.id)
  } else {
    await supabaseClient.from('supplements')
      .insert({
        order_id: order.id,
        description: 'igic',
        supplement: valueIgic.toFixed(2),
        status_id: (valueIgic > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
        volumetric: false,
        deleted: (valueIgic === 0)
      })
  }
}
