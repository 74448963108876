import { Button, Checkbox, CreateButton, EditButton, Form, FormProps, Icons, Input, List, Modal, Select, Space, Table, Typography, useDrawerForm, useEditableTable } from '@pankod/refine-antd'
import { useTranslate, IResourceComponentsProps, CrudFilters } from '@pankod/refine-core'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { CreateSupplier } from 'components/suppliers/create'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { filter, getSupplierExport, supabaseClient } from 'utility'

export const SupplierList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    searchFormProps,
    setId: setEditId
  } = useEditableTable<definitions['suppliers']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'id, created_at, cif, legal_name, legal_address, country, postal_code, province, verified'
    },
    hasPagination: true,
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { q } = params

      if (currentFilterSelect === 'cif') {
        filters.push(
          {
            field: 'cif',
            operator: 'contains',
            value: q
          }
        )
      } else {
        filters.push(
          {
            field: 'legal_name',
            operator: 'contains',
            value: q
          }
        )
      }
      setAdditionalFilters([{ field: (currentFilterSelect === 'cif') ? 'cif' : 'legal_name', operator: 'contains', value: q }])

      return filters
    }
  })

  const {
    drawerProps: createDrawerProps,
    saveButtonProps: createButtonProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['suppliers']>({
    action: 'create',
    resource: 'suppliers',
    redirect: false
  })

  const {
    drawerProps: editDrawerProps,
    saveButtonProps: editButtonProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['suppliers']>({
    action: 'edit',
    resource: 'suppliers',
    redirect: false
  })

  const [additionalFilters, setAdditionalFilters] = useState<filter[] | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<number>(-1)
  const [currentFilterSelect, setCurrentFilterSelect] = useState<String>('cif')

  const deleteSupplier = async () => {
    if (currentId > -1) {
      await supabaseClient
        .from('suppliers')
        .update({
          deleted: true
        })
        .eq('id', currentId)
    }
    setShowModal(false)
    setCurrentId(-1)
  }

  const t = useTranslate()

  async function exportSuppliers () {
    const data = await getSupplierExport(
      'id, created_at, cif, legal_name, legal_address, country, postal_code, province, verified',
      additionalFilters
    )

    return dataToExport(data as any[])
  }

  const dataToExport = (providers: any[]) => {
    return providers?.map((provider: any) => {
      return {
        [t('suppliers.fields.id')]: provider?.id,
        [t('suppliers.fields.CIF')]: provider?.cif,
        [t('suppliers.fields.legalName')]: provider?.legal_name,
        [t('suppliers.fields.legalAddress')]: provider?.legal_address,
        [t('suppliers.fields.postalCode')]: provider?.postal_code,
        [t('suppliers.fields.province')]: provider?.province,
        [t('suppliers.fields.verified')]: (provider?.verified) ? 1 : 0
      }
    })
  }

  const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
      <Form layout="vertical" {...formProps}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', width: '40%' }}>
            <Typography style={{ width: '20%', fontWeight: 600, marginRight: 20 }}>{t('suppliers.filterTitle')}</Typography>
            <Select style={{ width: '70%' }}
              options={[
                { label: t('suppliers.fields.legalName'), value: 'legal_name' },
                { label: t('suppliers.fields.CIF'), value: 'cif' }
              ]}
              defaultValue={currentFilterSelect}
              onChange={(value) => {
                setCurrentFilterSelect(value)
              }}
            />
          </div>
          <div style={{ width: '60%' }}>
            <Form.Item name="q">
              <Input
                  placeholder={t('suppliers.filterBy')}
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </div>
        </div>

        <Button style={{ marginBottom: '15px' }} htmlType="submit" type="primary">
          {t('suppliers.actions.filter')}
        </Button>
      </Form>
    )
  }

  return (
    <List title={t('suppliers.title')} headerButtons={
      <>
        <CreateButton onClick={() => createShow()}>
          {t('suppliers.actions.addSupplier')}
        </CreateButton>
        <ExportToExcelButton filename='Suppliers' asyncFunction={() => exportSuppliers()} />
      </>
    }>
      <Filter formProps={searchFormProps} />
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title='ID'
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="cif"
            title={t('suppliers.fields.CIF')}
          />
          <Table.Column
            dataIndex="legal_name"
            title={t('suppliers.fields.legalName')}
          />
          <Table.Column
            dataIndex="verified"
            title={t('suppliers.fields.verified')}
            render={(_, record: any) => {
              return (
                <>
                  <Checkbox style={{
                    alignItems: 'center'
                  }}
                  checked={record.verified} />
                </>
              )
            }}
          />
          <Table.Column
            dataIndex="legal_address"
            title={t('suppliers.fields.legalAddress')}
          />
          <Table.Column
            dataIndex="country"
            title={t('suppliers.fields.country')}
          />
          <Table.Column
            dataIndex="province"
            title={t('suppliers.fields.province')}
          />
          <Table.Column
            dataIndex="postal_code"
            title={t('suppliers.fields.postalCode')}
          />
          <Table.Column
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record: any) => {
              return (
                <>
                  <Space>
                    <EditButton
                      onClick={() => editShow(record.id)}
                      hideText
                      size='small'
                    />
                    <Button
                      onClick={() => {
                        setShowModal(true)
                        setCurrentId(record.id)
                      }}
                      icon={<Icons.DeleteOutlined style={{ color: 'red' }} />}
                      size='small'
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
      <CreateSupplier
        drawerProps={createDrawerProps}
        saveButtonProps={createButtonProps}
        formProps={createFormProps}
        close={createClose}
        isEdit={false}
      />
      <CreateSupplier
        drawerProps={editDrawerProps}
        saveButtonProps={editButtonProps}
        formProps={editFormProps}
        close={editClose}
        isEdit={true}
      />
      <Modal visible={showModal} onCancel={() => setShowModal(false)} onOk={() => deleteSupplier()}>
        <Space>
          <Typography>{t('suppliers.modalTitle')}</Typography>
        </Space>
      </Modal>
    </List>
  )
}
