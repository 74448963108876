import {
  CLIENT_STATUS_CONTACTED_ID,
  CLIENT_STATUS_CONTACTED_TEXT,
  CLIENT_STATUS_NO_REFUND_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT,
  CLIENT_STATUS_NO_REFUND_TEXT,
  CLIENT_STATUS_PENDING_REFUND_COSTS_ID,
  CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  CLIENT_STATUS_RECEIVED_ID,
  CLIENT_STATUS_RECEIVED_TEXT,
  CLIENT_STATUS_REFUNDED_ID,
  CLIENT_STATUS_REFUNDED_TEXT,
  CLIENT_STATUS_TIPSA_CONTACTED_ID,
  CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  ISSUE_SUB_TYPE_BROKEN_ORDER_ID,
  ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_ID,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  ISSUE_SUB_TYPE_CROSSED_ORDER_ID,
  ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  ISSUE_SUB_TYPE_GUARANTEE_ID,
  ISSUE_SUB_TYPE_GUARANTEE_TEXT,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_ID,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  ISSUE_SUB_TYPE_PENALTY_ID,
  ISSUE_SUB_TYPE_PENALTY_TEXT,
  ISSUE_SUB_TYPE_REFUND_ID,
  ISSUE_SUB_TYPE_REFUND_TEXT,
  ISSUE_SUB_TYPE_REPLACE_ID,
  ISSUE_SUB_TYPE_REPLACE_TEXT,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT,
  ISSUE_TYPE_AFTER_SALE_ID,
  ISSUE_TYPE_AFTER_SALE_TEXT,
  ISSUE_TYPE_REFUND_CANARY_ID,
  ISSUE_TYPE_REFUND_CANARY_TEXT,
  ISSUE_TYPE_REFUND_MADRID_ID,
  ISSUE_TYPE_REFUND_MADRID_TEXT,
  ISSUE_TYPE_TRANSPORT_ID,
  ISSUE_TYPE_TRANSPORT_TEXT,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_ID,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  ISSUE_STATUS_OFFICE_RECEIVED_ID,
  ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT,
  ISSUE_STATUS_PENDING_RESOLUTION_ID,
  ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  ISSUE_STATUS_REFUNDED_ID,
  ISSUE_STATUS_REFUNDED_TEXT,
  ISSUE_STATUS_REFUND_READY_ID,
  ISSUE_STATUS_REFUND_READY_TEXT,
  ISSUE_STATUS_REFUND_RECEIVED_ID,
  ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_NO_REFUND_RECEIVED_ID,
  ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_SENT_TO_MADRID_ID,
  ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  ISSUE_STATUS_STORAGE_RECEIVED_ID,
  ISSUE_STATUS_STORAGE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_ID,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  BUSINESS_STATUS_REQUESTED_ID,
  BUSINESS_STATUS_LABELLED_ID,
  BUSINESS_STATUS_RECEIVED_ID,
  BUSINESS_STATUS_SENT_ID,
  BUSINESS_STATUS_DELIVERED_ID,
  BUSINESS_STATUS_CANCELLED_ID,
  BUSINESS_STATUS_REQUESTED_TEXT,
  BUSINESS_STATUS_LABELLED_TEXT,
  BUSINESS_STATUS_RECEIVED_TEXT,
  BUSINESS_STATUS_SENT_TEXT,
  BUSINESS_STATUS_DELIVERED_TEXT,
  BUSINESS_STATUS_CANCELLED_TEXT,
  ACEPTED_STATUS_ID,
  ADVERTISEMENT_STATUS_ID,
  CANCELLED_STATUS_ID,
  SENT_BUDGE_STATUS_ID,
  ADVERTISEMENT_STATUS_TEXT,
  SENT_BUDGE_STATUS_TEXT,
  ACEPTED_STATUS_TEXT,
  CANCELLED_STATUS_TEXT
} from 'utility'

// BEGIN ISSUES TYPES
// Issue type ids
export type IssueTypeIdTypes = typeof ISSUE_TYPE_REFUND_MADRID_ID | typeof ISSUE_TYPE_REFUND_CANARY_ID | typeof ISSUE_TYPE_AFTER_SALE_ID | typeof ISSUE_TYPE_TRANSPORT_ID
export type IssueTypeTextTypes = typeof ISSUE_TYPE_REFUND_MADRID_TEXT | typeof ISSUE_TYPE_REFUND_CANARY_TEXT | typeof ISSUE_TYPE_AFTER_SALE_TEXT | typeof ISSUE_TYPE_TRANSPORT_TEXT
export type IssueTypesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueTypeIdTypes]: IssueTypeTextTypes
}

// Issue sub type ids
export type IssueSubTypeIdTypes =
  | typeof ISSUE_SUB_TYPE_CANCEL_STORAGE_ID
  | typeof ISSUE_SUB_TYPE_PENALTY_ID
  | typeof ISSUE_SUB_TYPE_PARTIAL_REFUND_ID
  | typeof ISSUE_SUB_TYPE_REFUND_ID
  | typeof ISSUE_SUB_TYPE_REPLACE_ID
  | typeof ISSUE_SUB_TYPE_GUARANTEE_ID
  | typeof ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID
  | typeof ISSUE_SUB_TYPE_BROKEN_ORDER_ID
  | typeof ISSUE_SUB_TYPE_CROSSED_ORDER_ID
  | typeof ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID
  | typeof ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID
export type IssueSubTypeTextTypes =
  | typeof ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT
  | typeof ISSUE_SUB_TYPE_PENALTY_TEXT
  | typeof ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT
  | typeof ISSUE_SUB_TYPE_REFUND_TEXT
  | typeof ISSUE_SUB_TYPE_REPLACE_TEXT
  | typeof ISSUE_SUB_TYPE_GUARANTEE_TEXT
  | typeof ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT
  | typeof ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT
export type IssueSubTypesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueSubTypeIdTypes]?: IssueSubTypeTextTypes
}

// Client status ids
export type ClientStatusIdTypes =
  | typeof CLIENT_STATUS_CONTACTED_ID
  | typeof CLIENT_STATUS_PENDING_REFUND_COSTS_ID
  | typeof CLIENT_STATUS_REFUNDED_ID
  | typeof CLIENT_STATUS_TIPSA_CONTACTED_ID
  | typeof CLIENT_STATUS_RECEIVED_ID
  | typeof CLIENT_STATUS_NO_REFUND_ID
  | typeof CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID
export type ClientStatusTextTypes =
  | typeof CLIENT_STATUS_CONTACTED_TEXT
  | typeof CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT
  | typeof CLIENT_STATUS_REFUNDED_TEXT
  | typeof CLIENT_STATUS_TIPSA_CONTACTED_TEXT
  | typeof CLIENT_STATUS_RECEIVED_TEXT
  | typeof CLIENT_STATUS_NO_REFUND_TEXT
  | typeof CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT
export type ClientStatusesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in ClientStatusIdTypes]?: ClientStatusTextTypes
}

// Issue status ids
export type IssueStatusIdTypes =
  | typeof ISSUE_STATUS_CONTACTED_MARKETPLACE_ID
  | typeof ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID
  | typeof ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID
  | typeof ISSUE_STATUS_REFUNDED_ID
  | typeof ISSUE_STATUS_REFUND_RECEIVED_ID
  | typeof ISSUE_STATUS_NO_REFUND_RECEIVED_ID
  | typeof ISSUE_STATUS_OFFICE_RECEIVED_ID
  | typeof ISSUE_STATUS_REFUND_READY_ID
  | typeof ISSUE_STATUS_SENT_TO_MADRID_ID
  | typeof ISSUE_STATUS_STORAGE_RECEIVED_ID
  | typeof ISSUE_STATUS_PENDING_RESOLUTION_ID
  | typeof ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID
  | typeof ISSUE_STATUS_PENDING_BRAND_CONTACT_ID
export type IssueStatusTextTypes =
  | typeof ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT
  | typeof ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT
  | typeof ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT
  | typeof ISSUE_STATUS_REFUNDED_TEXT
  | typeof ISSUE_STATUS_REFUND_RECEIVED_TEXT
  | typeof ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT
  | typeof ISSUE_STATUS_OFFICE_RECEIVED_TEXT
  | typeof ISSUE_STATUS_REFUND_READY_TEXT
  | typeof ISSUE_STATUS_SENT_TO_MADRID_TEXT
  | typeof ISSUE_STATUS_STORAGE_RECEIVED_TEXT
  | typeof ISSUE_STATUS_PENDING_RESOLUTION_TEXT
  | typeof ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT
  | typeof ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT
export type IssueStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueStatusIdTypes]?: IssueStatusTextTypes
}

export type IssueTypesConfig = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueTypeIdTypes]: {
    issueSubTypes: IssueSubTypesMapper,
    clientStatuses?: ClientStatusesMapper,
    issueStatuses?: IssueStatusMapper
  }
}
// END ISSUES CONSTANTS

export type BusinessStatusIdTypes = typeof BUSINESS_STATUS_REQUESTED_ID | typeof BUSINESS_STATUS_LABELLED_ID | typeof BUSINESS_STATUS_RECEIVED_ID | typeof BUSINESS_STATUS_SENT_ID | typeof BUSINESS_STATUS_DELIVERED_ID | typeof BUSINESS_STATUS_CANCELLED_ID
export type BusinessStatusTextTypes = typeof BUSINESS_STATUS_REQUESTED_TEXT | typeof BUSINESS_STATUS_LABELLED_TEXT | typeof BUSINESS_STATUS_RECEIVED_TEXT | typeof BUSINESS_STATUS_SENT_TEXT | typeof BUSINESS_STATUS_DELIVERED_TEXT | typeof BUSINESS_STATUS_CANCELLED_TEXT

export type BusinessStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in BusinessStatusIdTypes]: BusinessStatusTextTypes
}

export const isBusinessStatusId: (value: any) => value is BusinessStatusIdTypes = (value): value is BusinessStatusIdTypes => {
  return [
    BUSINESS_STATUS_REQUESTED_ID,
    BUSINESS_STATUS_LABELLED_ID,
    BUSINESS_STATUS_RECEIVED_ID,
    BUSINESS_STATUS_SENT_ID,
    BUSINESS_STATUS_DELIVERED_ID,
    BUSINESS_STATUS_CANCELLED_ID
  ].includes(value)
}

export type EstimatedCostsStatusIdTypes = typeof ADVERTISEMENT_STATUS_ID | typeof SENT_BUDGE_STATUS_ID | typeof ACEPTED_STATUS_ID | typeof CANCELLED_STATUS_ID
export type EstimatedCostsStatusTextTypes = typeof ADVERTISEMENT_STATUS_TEXT | typeof SENT_BUDGE_STATUS_TEXT | typeof ACEPTED_STATUS_TEXT | typeof CANCELLED_STATUS_TEXT
export type EstimatedCostsStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in EstimatedCostsStatusIdTypes]: EstimatedCostsStatusTextTypes
}
