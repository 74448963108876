import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Grid,
  Radio,
  Typography,
  Divider,
  SaveButton
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility/supabaseClient'
import { checkIssueDeadline, getVariableValue, updateCalculatedTotalOrder } from 'utility/supabaseApi'
import { ADMIN_ROLE_ID, ADMITTED_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, PENALIZATION_STATUS_TEXT, SENT_BUDGE_STATUS_ID, SUPPLEMENT_PREDEFINED_OPTIONS } from 'utility/constants'
import { Islands, Order, ShippingMethods, definitions } from 'interfaces'
import { QueryObserverResult } from '@tanstack/react-query'
import { currentUserMetadata } from 'utility/authLocal'
import { VolSuplementsForm } from './volSuplementForm'
import { NotVolSuplementsForm } from './notVolSuplementForm'
import moment from 'moment'

type SupplementActionsProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  closeDrawer: Function
  tableQueryResult: QueryObserverResult
  orderId: number
  edit?: boolean
  shippingMethod: ShippingMethods
  island: Islands,
  orderQuery: QueryObserverResult,
  packageNumber: number
  order: Order
  isEstimatedCost?: boolean
}

export const SupplementActions: React.FC<SupplementActionsProps> = ({
  drawerProps,
  formProps,
  closeDrawer,
  tableQueryResult,
  edit = false,
  orderId,
  shippingMethod,
  island,
  orderQuery,
  packageNumber,
  order,
  isEstimatedCost
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [loading, setLoading] = useState(false)
  // const [shippingPrice, setShippingPrice] = useState(0)
  const [volumetric, setVolumetric] = useState<boolean>(isEstimatedCost || false)
  const [maxKgDefault, setMaxKgDefault] = useState(0)
  const [statusId, setStatusId] = useState(ORDER_STATUS_ID_PENDING_PAYMENT)
  const [reload, setReload] = useState(true)

  let volSuplements: any
  let notVolSuplements: any

  const handleVolSuplements = (data: any) => {
    volSuplements = [...data]
  }

  const handleNotVolSuplements = (data: any) => {
    notVolSuplements = data
  }

  useEffect(() => {
    if (reload === false) {
      setReload(true)
    }
  }, [reload])

  const formData = formProps.initialValues

  getVariableValue('MAX_KG_DEFAULT_SHIPPING_PRICE', setMaxKgDefault)

  const extraCost = shippingMethod.shipment_type === 'plane'
    ? island.plane_extra_cost
    : island.boat_extra_cost

  if (edit) {
    useEffect(() => {
      setVolumetric(formData?.volumetric)
      setStatusId(formData?.status_id || ORDER_STATUS_ID_PENDING_PAYMENT)
    }, [formData])
  }

  const checkRequiredReload = (states: any) => {
    if (!(states.supplement > 0 && (states.description === null || states.description !== 'penalization'))) {
      window.location.reload()
    }
  }

  const isAdmin = currentUserMetadata()?.role_id >= ADMIN_ROLE_ID || false

  const supplementOptions = SUPPLEMENT_PREDEFINED_OPTIONS.map((option) => ({ value: option, label: t(`supplement.descriptions.${option}`) }))

  const VolumetricForm = () => {
    return (
      <>
        {<div>
          <VolSuplementsForm
            edit={edit}
            isAdmin={isAdmin}
            formData={formData}
            packageNumber={packageNumber}
            orderId={orderId}
            handleVolSuplements={handleVolSuplements}
            shippingMethod={shippingMethod}
            maxKgDefault={maxKgDefault}
            extraCost={extraCost}
            order={order} />
        </div>}
      </>
    )
  }

  const NoVolumetricForm = () => {
    return (<NotVolSuplementsForm
      edit={edit}
      supplementData={formData}
      supplementOptions={supplementOptions}
      shippingMethod={shippingMethod}
      handleNotVolSuplements={handleNotVolSuplements}
    />)
  }

  const saveSup = async () => {
    const resource = isEstimatedCost ? 'estimated_costs' : 'supplements'
    setLoading(true)
    if (notVolSuplements !== undefined) {
      if (notVolSuplements.id) {
        await updateSupplement(notVolSuplements, resource)
      } else {
        await insertSupplement(notVolSuplements, resource)
      }
      notVolSuplements = undefined
    }

    if (volSuplements !== undefined) {
      for (const sup of volSuplements) {
        if (sup.id) {
          await updateSupplement(sup, resource)
        } else {
          await insertSupplement(sup, resource)
        }
      }
    }
    setLoading(false)
  }

  const calculateRefundStore = async (supplement: number, iva?: number) => {
    const { data, error } = await supabaseClient.from('orders').select('shipping_method_id').eq('id', orderId).single()

    const { data: dataEconomyShipment, error: errorShipment } = await supabaseClient.from('shipment_methods').select('id').eq('shipMode', true).eq('is_in_home', true).single()

    if (error || errorShipment) {
      return supplement
    }

    // eslint-disable-next-line camelcase
    if (data.shipping_method_id !== dataEconomyShipment.id) {
      const percent = (iva !== undefined && iva >= 0) ? Number(`1.${iva}`) : 1.21
      return (supplement / percent).toFixed(2)
    }
    return supplement
  }

  const insertSupplement = async (dataToInsert: any, resource: string) => {
    // eslint-disable-next-line camelcase
    const { volumetric, weigth, length, heigth, depth, volume, billable_volume, weigth_excess, description, reason, volumetricInfo } = dataToInsert

    let { supplement } = dataToInsert

    supplement = (description === 'refundStore') ? await calculateRefundStore(supplement, (order?.marketplace_id?.id > 0 && order?.marketplace_id?.iva_percent >= 0) ? order?.marketplace_id?.iva_percent : undefined) : supplement

    let status = supplement <= 0 || description === PENALIZATION_STATUS_TEXT ? ADMITTED_STATUS : statusId
    if (isEstimatedCost) {
      status = SENT_BUDGE_STATUS_ID
    }

    const state = {
      order_id: orderId,
      volumetric: volumetric,
      weigth: volumetric ? weigth : null,
      length: volumetric ? length : null,
      heigth: volumetric ? heigth : null,
      depth: volumetric ? depth : null,
      volume: volumetric ? volume : null,
      // eslint-disable-next-line camelcase
      billable_volume: volumetric ? billable_volume : null,
      // eslint-disable-next-line camelcase
      weigth_excess: volumetric ? weigth_excess : null,
      supplement: supplement,
      description: !volumetric ? description : null,
      reason: (description === 'other' || description === 'refund') ? reason : null,
      status_id: status,
      volumetricInfo: volumetric ? volumetricInfo : null
    }

    await supabaseClient.from(resource).insert(state)

    const arrayPromise = []
    if (!isEstimatedCost) arrayPromise.push(updateCalculatedTotalOrder(orderId))
    if (!volumetric && (description?.toLowerCase() === 'refundtransport' || description?.toLowerCase() === 'refundByCancelation' || description?.toLowerCase() === 'refundstore' || description?.toLowerCase() === 'refund')) {
      const refund = {
        description: (description === 'refund') ? reason : description,
        amount: Math.abs(supplement),
        status_id: 0,
        order_id: orderId,
        type: 'client'
      }

      arrayPromise.push(supabaseClient.from('refunds').insert(refund))
    }
    if (volumetric && volumetricInfo) {
      arrayPromise.push(supabaseClient.from('orders').update({
        package_number: Number(volumetricInfo.length) || 1
      }).eq('id', orderId))
    }

    if (isEstimatedCost) {
      arrayPromise.push(supabaseClient.from('orders')
        .update({
          estimated_costs_status: SENT_BUDGE_STATUS_ID
        })
        .eq('id', orderId))
    }

    if (volumetric) {
      checkIssueDeadline(orderId)
    }

    await Promise.all(arrayPromise)

    tableQueryResult.refetch()
    orderQuery.refetch()
    closeDrawer()
    checkRequiredReload(state)
  }

  const updateSupplement = async (dataToUpdate: any, resource: string) => {
    // eslint-disable-next-line camelcase
    const { volumetric, weigth, length, heigth, depth, volume, billable_volume, weigth_excess, description, reason, id, volumetricInfo } = dataToUpdate

    let { supplement } = dataToUpdate

    supplement = (description === 'refundStore') ? await calculateRefundStore(supplement) : supplement

    let status = supplement <= 0 || description === PENALIZATION_STATUS_TEXT ? ADMITTED_STATUS : statusId
    if (isEstimatedCost) {
      status = SENT_BUDGE_STATUS_ID
    }

    const state = {
      order_id: orderId,
      volumetric: volumetric,
      weigth: volumetric ? weigth : null,
      length: volumetric ? length : null,
      heigth: volumetric ? heigth : null,
      depth: volumetric ? depth : null,
      volume: volumetric ? volume : null,
      // eslint-disable-next-line camelcase
      billable_volume: volumetric ? billable_volume : null,
      // eslint-disable-next-line camelcase
      weigth_excess: volumetric ? weigth_excess : null,
      supplement: supplement,
      description: !volumetric ? description : null,
      reason: description === 'other' ? reason : null,
      status_id: status,
      volumetricInfo: volumetric ? volumetricInfo : null,
      updated_at: moment()
    }

    await supabaseClient.from(resource).update(state).eq('id', id)

    const arrayPromise = []
    if (volumetric && volumetricInfo) {
      arrayPromise.push(supabaseClient.from('orders').update({
        package_number: Number(volumetricInfo.length) || 1
      }).eq('id', orderId))
    }

    if (!isEstimatedCost) arrayPromise.push(updateCalculatedTotalOrder(orderId))

    if (!volumetric && (description?.toLowerCase() === 'refundtransport' || description?.toLowerCase() === 'refund' || description?.toLowerCase() === 'refundByCancelation' || description?.toLowerCase() === 'refundstore')) {
      const ref = await supabaseClient.from<definitions['refunds']>('refunds').select('*').eq('order_id', orderId).like('description', description).single()

      if (ref?.data) {
        arrayPromise.push(supabaseClient.from('refunds').update({ amount: Math.abs(supplement) }).eq('id', ref.data.id))
      } else {
        arrayPromise.push(supabaseClient.from('refunds').insert({
          description: description,
          amount: Math.abs(supplement),
          status_id: 0,
          order_id: orderId,
          type: 'client'
        }))
      }
    }

    await Promise.all(arrayPromise)

    tableQueryResult.refetch()
    orderQuery.refetch()
    closeDrawer()
    checkRequiredReload(state)
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      {Number.isInteger(formData?.order_id) && (
        <div style={{ margin: '1.5rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>{edit ? t('order.actions.editSupplement') : t('order.actions.addSupplement')}</Typography>
          </div>
          <Form
            style={{ margin: '1rem' }}
            layout="vertical"
            // onFinish={edit ? updateSupplement : insertSupplement}
          >
            <Form.Item
              hidden={true}
              name="order_id"
            >
              <Input />
            </Form.Item>
            {(!isEstimatedCost) && <Form.Item
              label={t('supplement.isVolumetric')}
              name="volumetric"
              hidden={edit}
            >
              <Radio.Group onChange={(event) => setVolumetric(event.target.value)}>
                <Radio value={true}>{t('status.yes')}</Radio>
                <Radio value={false}>{t('status.no')}</Radio>
              </Radio.Group>
            </Form.Item>}
            {volumetric && <VolumetricForm />}
            {!volumetric && <NoVolumetricForm />}
            <Divider />
            <SaveButton
              loading={loading}
              style={{ float: 'right', marginBottom: '5rem' }}
              onClick={() => saveSup()}
            >
              {t('buttons.save')}
            </SaveButton>
          </Form>
        </div>
      )}

    </Drawer>
  )
}
