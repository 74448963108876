import {
  CreateButton,
  List,
  Space,
  EditButton,
  Table,
  useDrawerForm,
  ShowButton,
  useModal,
  Collapse,
  Typography
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { CreateMarketOrder } from 'components/market_order/create'
import { definitions } from 'interfaces'
import { currency } from 'utility/currency'

import { EditMarketOrder } from 'components/market_order/edit'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { useEffect, useState } from 'react'
import { DeleteModal } from 'components/DeleteModal/modal'
import { formatDateTime, getGroupedMarketplace, prepareItemUrl, prepareUrl, supabaseClient } from 'utility'
import { ExtraInfoMarkeplaces } from 'components/ExtraInfoMarkeplaces/ExtraInfoMarkeplaces'

type MarketOrdersProps = {
  order: definitions['Order'] | undefined;
};

export const MarketOrders: React.FC<MarketOrdersProps> = (props) => {
  const t = useTranslate()

  let callingServer = false

  const order = props?.order

  const { Panel } = Collapse

  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)
  const [customShopData, setCustomShopData] = useState<any>(null)
  const [retrieveShop, setRetrieveShop] = useState<boolean>(true)
  const [relatedMarketOrders, setRelatedMarketOrder] = useState<any[]>([])

  const [marketplaceShipmentPrice, setMarketplaceShipmentPrice] = useState<number>(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [tableProps, setTableProps] = useState<any>({ dataSource: [] })

  const retrieveMarketOrders = async () => {
    if (!callingServer) {
      callingServer = true
      const { data: marketData } = await supabaseClient.from('market_orders').select('*')
        .eq('order_id', order?.id)
        .eq('deleted', false)
        .order('id', { ascending: true })

      const tableProps = { dataSource: marketData }

      setTableProps(tableProps)
    }
    callingServer = false
  }

  useEffect(() => {
    // console.log('sorter -->', sorter)
    if (!callingServer) {
      if (tableProps.dataSource.length > 0) {
        retrieveMarketOrders()
      }
    }
  }, [currentPage])

  useEffect(() => {
    retrieveMarketOrders()
  }, [])

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['market_orders']>({
    action: 'create',
    resource: 'market_orders',
    redirect: false
  })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['market_orders']>({
    action: 'edit',
    resource: 'market_orders',
    redirect: false
  })

  createFormProps.initialValues = { order_id: order?.id }

  const extraInfo = (typeof order?.marketplace_id?.extra_info === 'string') ? JSON.parse(order?.marketplace_id?.extra_info) : order?.marketplace_id?.extra_info
  const marketId = order?.marketplace_id?.id || 0
  const subtotal = order?.subtotal || 0

  useEffect(() => {
    const refundTransportSupplements = order?.supplements
      .filter(s => !s.volumetric && !s.deleted && (s.description === 'refundTransport' || s.description === 'transportToPeninsula' || s.description === 'transportSupplement'))
      .map(s => s.supplement || 0)
      .reduce((a, b) => a + b, 0) || 0

    setMarketplaceShipmentPrice((order?.marketplace_id.shipment_price || 0) + (refundTransportSupplements))
  }, [order])

  const expectedMarketOrderPrice = subtotal

  const retrieveCustomShopInfo = async () => {
    let customUrl: string | null = null

    order?.items?.forEach((i: any) => {
      if (customUrl === null && i.url.startsWith('http')) {
        customUrl = (i?.custom_order_site) ? i.custom_order_site : prepareUrl(i.url)
      }
    })

    if (customUrl && customUrl !== '') {
      const res = await getGroupedMarketplace(prepareItemUrl(customUrl))
      if (res.internal || res.related) {
        setCustomShopData(res)
      }
    }
    setRetrieveShop(false)
  }

  if (marketId === 0 && retrieveShop) {
    retrieveCustomShopInfo()
  }

  const customShopHaveInfo = () => {
    if ((customShopData?.internal && typeof customShopData?.internal === 'object') ||
      (customShopData?.internal && customShopData?.internal[0] && customShopData?.internal[0].extra_info)) {
      // (customShopData?.related && customShopData?.related[0] && customShopData?.related[0].extra_info)) {
      return true
    } else {
      return false
    }
  }

  const backgroundColorStyle = ((extraInfo !== '' && extraInfo !== null && extraInfo?.info && extraInfo?.info.length > 0) || customShopHaveInfo()) ? '#f5f5cb' : 'transparent'

  return (
    <List key={(order?.marketplace_id.shipment_price || 0) + (order?.supplements.length || 0)}
      title={`${t('market_order.market_orders')} - ${t('market_order.expectedPrice')}: ${currency(expectedMarketOrderPrice)} / ${t('market_order.marketplaceShipmentPrice')}: ${currency(marketplaceShipmentPrice)}`}
      breadcrumb={false}
    >
      <CreateButton onClick={() => createShow()} style={{ marginTop: -50, float: 'right' }}>
        {t('order.actions.addMarketOrder')}
      </CreateButton>

      {(order?.promotional_code_market !== null)
        ? <div style={{ backgroundColor: 'khaki', borderRadius: '5px' }}><Typography style={{ margin: '0px 10px 20px 10px' }} >{t('market_order.fields.promotionalCodeMarket')} <b>{order?.promotional_code_market}</b></Typography></div>
        : null}

      <Collapse key={`${marketId}${customShopData?.internal?.length}${customShopData?.related?.length}`} style={{ backgroundColor: `${backgroundColorStyle}`, marginBottom: '15px' }} bordered={false} ghost={false} defaultActiveKey={['1']}>
        <Panel style={{ backgroundColor: 'transparent' }} header={t('market_order.fields.extraInfo')} key="1">
          {(!customShopData) ? <ExtraInfoMarkeplaces extraInfo={extraInfo} id={marketId} /> : <ExtraInfoMarkeplaces customShopData={customShopData} id={marketId} />}
        </Panel>
      </Collapse>

      <Table key={`marketOrder${tableProps?.dataSource?.length || 0}`} {...tableProps}
        pagination={{
          current: currentPage,
          pageSize: 4,
          total: tableProps?.dataSource?.length || 0,
          onChange (page, pageSize) {
            setCurrentPage(page)
          }
        }}
        rowKey="id">
        <Table.Column
          key="purchase_id"
          dataIndex="purchase_id"
          title={t('market_order.fields.purchaseId')}
          align='center'
          sorter
        />
        <Table.Column
          key="transaction_id"
          dataIndex="transaction_id"
          title={t('market_order.fields.transactionId')}
          align='center'
          sorter
        />
        <Table.Column
          key="purchase_amount"
          dataIndex="purchase_amount"
          title={t('market_order.fields.purchaseAmount')}
          render={(value) => currency(value)}
          align='center'
          sorter
        />
        <Table.Column
          key="iva_type"
          dataIndex="iva_type"
          title={'Tipo de IVA'}
          align='center'
          sorter
        />
        <Table.Column
          key="iva_amount"
          dataIndex="iva_amount"
          title={'IVA (€)'}
          align='center'
          sorter
        />
        <Table.Column
          key="tracking_id"
          dataIndex="tracking_id"
          title={t('market_order.fields.trackingId')}
          align='center'
          sorter
        />
        <Table.Column
          key="invoice_number"
          dataIndex="invoice_number"
          title={t('market_order.fields.invoiceNumber')}
          align='center'
          sorter
        />
        <Table.Column
          key="invoice_date"
          dataIndex="invoice_date"
          title={t('market_order.fields.invoiceDate')}
          align='center'
          render={(value) => value ? formatDateTime(value, true) : ''}
          sorter
        />
        <Table.Column
          key="file_url"
          dataIndex="file_url"
          align='center'
          title={t('market_order.fields.invoiceFile')}
          render={(value, record: any) => {
            if (value && record?.purchase_parent) return <ShowButton onClick={() => window.open(value, '_blank', 'noopener,noreferrer')}></ShowButton>
            else {
              const parent = tableProps.dataSource.find((x: any) => x.purchase_id === record.purchase_id && x.file_url !== null && x.invoice_number === record.invoice_number)
              if (parent && record?.is_purchase === true) return <>{t('market_order.fields.shared')}</>
              else return null
            }
          }}
        />
        <Table.Column
          key="is_purchase"
          dataIndex="is_purchase"
          title={t('market_order.fields.type')}
          align='center'
          render={(value) => value ? t('market_order.fields.purchase') : t('market_order.fields.refund')}
          sorter
        />
        <Table.Column<definitions['market_orders']>
            title={t('table.actions')}
            dataIndex="actions"
            render={(_, record) => {
              if (record.purchase_parent) {
                return <Space>
                  <EditButton
                    onClick={() => {
                      editShow(record.id)
                      let array = []
                      if (record.is_purchase) {
                        array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === true && mo.invoice_number === record.invoice_number) || []
                      } else {
                        if (record.invoice_number !== '' && record.invoice_number !== null) {
                          array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === false && mo.invoice_number === record.invoice_number) || []
                        } else {
                          array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === false) || []
                        }
                      }

                      setRelatedMarketOrder(array)
                    }}
                    hideText
                  />
                  <ButtonLogicDelete click={() => {
                    show()
                    setId(record.id)
                    let array = []
                    if (record.is_purchase) {
                      if (record.invoice_number !== '' && record.invoice_number !== null) {
                        array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === true && mo.invoice_number === record.invoice_number) || []
                      } else {
                        array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === true) || []
                      }
                    } else {
                      if (record.invoice_number !== '' && record.invoice_number !== null) {
                        array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === false && mo.invoice_number === record.invoice_number) || []
                      } else {
                        array = tableProps.dataSource?.filter((mo: any) => mo.purchase_id === record.purchase_id && mo.is_purchase === false) || []
                      }
                    }
                    setRelatedMarketOrder(array)
                  }} />
                  <DeleteModal
                    modalProps={modalProps}
                    close={close}
                    id={id}
                    relatedMarketOrders={relatedMarketOrders}
                    entity='market_orders'
                    tableQueryResult={retrieveMarketOrders}
                  />
                </Space>
              } else return null
            }}
          />
      </Table>
      <CreateMarketOrder
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        tableQueryResult={retrieveMarketOrders}
        closeDrawer={createClose}
        defaultMarketplace={order?.marketplace_id}
        order={order}
      />
      <EditMarketOrder
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        tableQueryResult={retrieveMarketOrders}
        closeDrawer={editClose}
        relatedPurchases={relatedMarketOrders}
        defaultMarketplace={order?.marketplace_id}
        order={order}
      />
    </List>
  )
}
