import dayjs from 'dayjs'
import {
  Create,
  Drawer,
  DrawerProps,
  Edit,
  FormProps,
  Grid
} from '@pankod/refine-antd'
import { useTranslation } from 'react-i18next'
import { IssueForm } from './form'
import { checkIssueDeadline, supabaseClient } from 'utility'
import { useNotification } from '@pankod/refine-core'
import { useContext, useEffect, useState } from 'react'
import { IssuesContext } from './show'
import { definitions } from 'interfaces'

type IssueProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  close: () => void
  refetchFn?: () => void
  isCreate?: boolean
  issueTypeId?: number
  orderId?: number
}

export const IssueDrawer: React.FC<IssueProps> = ({
  drawerProps,
  formProps,
  close,
  refetchFn,
  isCreate = true,
  issueTypeId,
  orderId
}) => {
  const { setRefetch } = useContext(IssuesContext)
  const breakpoint = Grid.useBreakpoint()
  const { t } = useTranslation()
  const { open } = useNotification()
  const [itemIds, setItemIds] = useState<number[]>()
  const Action = isCreate ? Create : Edit
  const langAction = isCreate ? 'create' : 'edit'
  const breakPointSM = isCreate ? '400px' : '100vh'

  const successNotification = () => open?.({
    type: 'success',
    message: t('notifications.successMsg')
  })

  const errorNotification = () => open?.({
    type: 'error',
    message: t('notifications.errorMsg')
  })

  useEffect(() => {
    if (!isCreate && orderId) {
      const issueId = formProps.initialValues?.id
      supabaseClient
        .from('items')
        .select('id')
        .eq('order_id', orderId)
        .eq('issue_id', issueId)
        .then(data => setItemIds(data.data?.map(item => item.id) || []))
    }
  }, [])

  if (!isCreate && orderId && !itemIds) return null

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? breakPointSM : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Action
        saveButtonProps={{
          onClick: async (e: any) => {
            const _issueTypeId = formProps.form?.getFieldValue('issue_type_id')
            if (isCreate) {
              const orderId = formProps.form?.getFieldValue('order_id')
              const { data } = await supabaseClient
                .from('orders')
                .select('marketplaces(deadline)')
                .eq('id', orderId)

              if (!data || !data.length) {
                errorNotification()
                return
              }

              const deadline = data![0].marketplaces.deadline
              const issue = {
                issue_type_id: issueTypeId || _issueTypeId,
                order_id: orderId,
                delivery_deadline: deadline ? dayjs().add(deadline, 'day') : null,
                issue_sub_type_id: formProps.form?.getFieldValue('issue_sub_type_id')
              }

              const { data: dataIssue, error } = await supabaseClient
                .from('issues')
                .insert(issue)

              await checkIssueDeadline(orderId, dataIssue?.[0]?.id)

              if (error) {
                errorNotification()
                return
              }
              window.open(`/issues/show/${dataIssue[0].id}`)
            } else {
              const issueId = formProps.initialValues?.id

              const issue: Omit<definitions['issues'], 'id' | 'order_id' | 'created_at' | 'deleted'> = {
                issue_type_id: _issueTypeId,
                issue_sub_type_id: formProps.form?.getFieldValue('issue_sub_type_id') || null,
                client_status_id: formProps.form?.getFieldValue('client_status_id') || null,
                issue_status_id: formProps.form?.getFieldValue('issue_status_id') || null,
                reason: formProps.form?.getFieldValue('reason') || null,
                notes: formProps.form?.getFieldValue('notes') || null,
                notes_marketplace: formProps.form?.getFieldValue('notes_marketplace') || null,
                request_date: formProps.form?.getFieldValue('request_date') || null,
                shipping_method: formProps.form?.getFieldValue('shipping_method') || null,
                shipper: formProps.form?.getFieldValue('shipper') || null,
                tracking_id: formProps.form?.getFieldValue('tracking_id') || null,
                issue_deadline: formProps.form?.getFieldValue('issue_deadline') || null,
                delivery_deadline: formProps.form?.getFieldValue('delivery_deadline') || null,
                is_opened: formProps.form?.getFieldValue('is_opened'),
                is_completed: formProps.form?.getFieldValue('is_completed'),
                updated_by: supabaseClient.auth.user()?.email
              }
              const { error } = await supabaseClient
                .from('issues')
                .update(issue)
                .eq('id', issueId)

              if (error) {
                errorNotification()
                return
              }

              const { error: selectError, data } = await supabaseClient
                .from('items')
                .select('id')
                .eq('order_id', orderId)

              if (selectError) {
                errorNotification()
                return
              }

              const orderItemIds = data?.map((item) => item.id) || []
              const itemIds: number[] = formProps.form?.getFieldValue('item_ids') || []
              const itemIdsSet = new Set(itemIds)
              const { toAdd, toRemove } = orderItemIds.reduce((acc: { toAdd: number[], toRemove: number[] }, curr) => {
                if (itemIdsSet.has(curr)) {
                  acc.toAdd.push(curr)
                } else {
                  acc.toRemove.push(curr)
                }
                return acc
              }, { toAdd: [], toRemove: [] })

              if (toAdd.length) {
                await supabaseClient
                  .from('items')
                  .update({ issue_id: issueId })
                  .in('id', toAdd)
              }

              if (toRemove.length) {
                await supabaseClient
                  .from('items')
                  .update({ issue_id: null })
                  .in('id', toRemove)
              }

              successNotification()
            }

            refetchFn?.()
            setRefetch?.(true)
            close()
          }
        }}
        title={t(`issues.titles.${langAction}`)}
        breadcrumb={false}
        goBack={false}
        headerProps={{ extra: null }}
      >
        <IssueForm
          formProps={formProps}
          isCreate={isCreate}
          issueTypeId={issueTypeId}
          orderId={orderId}
          itemIds={itemIds}
        />
      </Action>
    </Drawer>
  )
}
