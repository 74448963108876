import { ClientStatusesMapper, IssueTypesConfig, IssueSubTypesMapper, IssueTypeIdTypes, IssueTypesMapper, IssueStatusMapper, BusinessStatusMapper, EstimatedCostsStatusMapper } from 'types/types'
import {
  CLIENT_STATUS_CONTACTED_ID,
  CLIENT_STATUS_CONTACTED_TEXT,
  CLIENT_STATUS_NO_REFUND_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT,
  CLIENT_STATUS_NO_REFUND_TEXT,
  CLIENT_STATUS_PENDING_REFUND_COSTS_ID,
  CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  CLIENT_STATUS_RECEIVED_ID,
  CLIENT_STATUS_RECEIVED_TEXT,
  CLIENT_STATUS_REFUNDED_ID,
  CLIENT_STATUS_REFUNDED_TEXT,
  CLIENT_STATUS_TIPSA_CONTACTED_ID,
  CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  ISSUE_SUB_TYPE_BROKEN_ORDER_ID,
  ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_ID,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  ISSUE_SUB_TYPE_CROSSED_ORDER_ID,
  ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  ISSUE_SUB_TYPE_GUARANTEE_ID,
  ISSUE_SUB_TYPE_GUARANTEE_TEXT,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_ID,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  ISSUE_SUB_TYPE_PENALTY_ID,
  ISSUE_SUB_TYPE_PENALTY_TEXT,
  ISSUE_SUB_TYPE_REFUND_ID,
  ISSUE_SUB_TYPE_REFUND_TEXT,
  ISSUE_SUB_TYPE_REPLACE_ID,
  ISSUE_SUB_TYPE_REPLACE_TEXT,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT,
  ISSUE_TYPE_AFTER_SALE_ID,
  ISSUE_TYPE_AFTER_SALE_TEXT,
  ISSUE_TYPE_REFUND_CANARY_ID,
  ISSUE_TYPE_REFUND_CANARY_TEXT,
  ISSUE_TYPE_REFUND_MADRID_ID,
  ISSUE_TYPE_REFUND_MADRID_TEXT,
  ISSUE_TYPE_TRANSPORT_ID,
  ISSUE_TYPE_TRANSPORT_TEXT,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_ID,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  ISSUE_STATUS_OFFICE_RECEIVED_ID,
  ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT,
  ISSUE_STATUS_PENDING_RESOLUTION_ID,
  ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  ISSUE_STATUS_REFUNDED_ID,
  ISSUE_STATUS_REFUNDED_TEXT,
  ISSUE_STATUS_REFUND_READY_ID,
  ISSUE_STATUS_REFUND_READY_TEXT,
  ISSUE_STATUS_REFUND_RECEIVED_ID,
  ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_NO_REFUND_RECEIVED_ID,
  ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_SENT_TO_MADRID_ID,
  ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  ISSUE_STATUS_STORAGE_RECEIVED_ID,
  ISSUE_STATUS_STORAGE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_ID,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  BUSINESS_STATUS_REQUESTED_ID,
  BUSINESS_STATUS_LABELLED_ID,
  BUSINESS_STATUS_RECEIVED_ID,
  BUSINESS_STATUS_SENT_ID,
  BUSINESS_STATUS_DELIVERED_ID,
  BUSINESS_STATUS_CANCELLED_ID,
  BUSINESS_STATUS_REQUESTED_TEXT,
  BUSINESS_STATUS_LABELLED_TEXT,
  BUSINESS_STATUS_RECEIVED_TEXT,
  BUSINESS_STATUS_SENT_TEXT,
  BUSINESS_STATUS_DELIVERED_TEXT,
  BUSINESS_STATUS_CANCELLED_TEXT,
  ACEPTED_STATUS_ID,
  ACEPTED_STATUS_TEXT,
  ADVERTISEMENT_STATUS_ID,
  ADVERTISEMENT_STATUS_TEXT,
  CANCELLED_STATUS_ID,
  CANCELLED_STATUS_TEXT,
  SENT_BUDGE_STATUS_ID,
  SENT_BUDGE_STATUS_TEXT
} from './constants'

export const ISSUE_TYPES_MAPPER: IssueTypesMapper = {
  [ISSUE_TYPE_REFUND_MADRID_ID]: ISSUE_TYPE_REFUND_MADRID_TEXT,
  [ISSUE_TYPE_REFUND_CANARY_ID]: ISSUE_TYPE_REFUND_CANARY_TEXT,
  [ISSUE_TYPE_AFTER_SALE_ID]: ISSUE_TYPE_AFTER_SALE_TEXT,
  [ISSUE_TYPE_TRANSPORT_ID]: ISSUE_TYPE_TRANSPORT_TEXT
}

export const ISSUE_SUB_TYPES_REF_MADRID_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_CANCEL_STORAGE_ID]: ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  [ISSUE_SUB_TYPE_PENALTY_ID]: ISSUE_SUB_TYPE_PENALTY_TEXT
}

export const ISSUE_SUB_TYPES_REF_CANARY_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_PARTIAL_REFUND_ID]: ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  [ISSUE_SUB_TYPE_REFUND_ID]: ISSUE_SUB_TYPE_REFUND_TEXT
}

export const ISSUE_SUB_TYPES_AFTER_SALE_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_REPLACE_ID]: ISSUE_SUB_TYPE_REPLACE_TEXT,
  [ISSUE_SUB_TYPE_GUARANTEE_ID]: ISSUE_SUB_TYPE_GUARANTEE_TEXT
}

export const ISSUE_SUB_TYPES_TRANSPORT_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID]: ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  [ISSUE_SUB_TYPE_BROKEN_ORDER_ID]: ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  [ISSUE_SUB_TYPE_CROSSED_ORDER_ID]: ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  [ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID]: ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  [ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID]: ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT
}

export const ISSUE_SUB_TYPES_MAPPER: IssueSubTypesMapper = {
  ...ISSUE_SUB_TYPES_REF_MADRID_MAPPER,
  ...ISSUE_SUB_TYPES_REF_CANARY_MAPPER,
  ...ISSUE_SUB_TYPES_AFTER_SALE_MAPPER,
  ...ISSUE_SUB_TYPES_TRANSPORT_MAPPER
}

export const CLIENT_STATUSES_REF_MADRID_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_PENDING_REFUND_COSTS_ID]: CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const CLIENT_STATUSES_REF_CANARY_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_TIPSA_CONTACTED_ID]: CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  [CLIENT_STATUS_RECEIVED_ID]: CLIENT_STATUS_RECEIVED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT,
  [CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID]: CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT
}

export const CLIENT_STATUSES_REF_AFTER_SALE_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_TIPSA_CONTACTED_ID]: CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  [CLIENT_STATUS_RECEIVED_ID]: CLIENT_STATUS_RECEIVED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT,
  [CLIENT_STATUS_NO_REFUND_ID]: CLIENT_STATUS_NO_REFUND_TEXT
}

export const CLIENT_STATUSES_MAPPER: ClientStatusesMapper = {
  ...CLIENT_STATUSES_REF_MADRID_MAPPER,
  ...CLIENT_STATUSES_REF_CANARY_MAPPER,
  ...CLIENT_STATUSES_REF_AFTER_SALE_MAPPER
}

export const ISSUE_STATUS_REF_MADRID_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID]: ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  [ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID]: ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT,
  [ISSUE_STATUS_REFUND_RECEIVED_ID]: ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_NO_REFUND_RECEIVED_ID]: ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT
}

export const ISSUE_STATUS_REF_CANARY_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID]: ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT,
  [ISSUE_STATUS_REFUND_RECEIVED_ID]: ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_NO_REFUND_RECEIVED_ID]: ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_OFFICE_RECEIVED_ID]: ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  [ISSUE_STATUS_REFUND_READY_ID]: ISSUE_STATUS_REFUND_READY_TEXT,
  [ISSUE_STATUS_SENT_TO_MADRID_ID]: ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  [ISSUE_STATUS_STORAGE_RECEIVED_ID]: ISSUE_STATUS_STORAGE_RECEIVED_TEXT,
  [ISSUE_STATUS_PENDING_BRAND_CONTACT_ID]: ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT
}

export const ISSUE_STATUS_REF_AFTER_SALE_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID]: ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT,
  [ISSUE_STATUS_REFUND_RECEIVED_ID]: ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_NO_REFUND_RECEIVED_ID]: ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_OFFICE_RECEIVED_ID]: ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  [ISSUE_STATUS_REFUND_READY_ID]: ISSUE_STATUS_REFUND_READY_TEXT,
  [ISSUE_STATUS_SENT_TO_MADRID_ID]: ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  [ISSUE_STATUS_STORAGE_RECEIVED_ID]: ISSUE_STATUS_STORAGE_RECEIVED_TEXT,
  [ISSUE_STATUS_PENDING_RESOLUTION_ID]: ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  [ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID]: ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  [ISSUE_STATUS_PENDING_BRAND_CONTACT_ID]: ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT
}

export const ISSUE_STATUS_MAPPER: IssueStatusMapper = {
  ...ISSUE_STATUS_REF_MADRID_MAPPER,
  ...ISSUE_STATUS_REF_CANARY_MAPPER,
  ...ISSUE_STATUS_REF_AFTER_SALE_MAPPER
}

export const ISSUE_CONFIG: IssueTypesConfig = {
  [ISSUE_TYPE_REFUND_MADRID_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_REF_MADRID_MAPPER,
    clientStatuses: CLIENT_STATUSES_REF_MADRID_MAPPER,
    issueStatuses: ISSUE_STATUS_MAPPER
  },
  [ISSUE_TYPE_REFUND_CANARY_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_REF_CANARY_MAPPER,
    clientStatuses: CLIENT_STATUSES_REF_CANARY_MAPPER,
    issueStatuses: ISSUE_STATUS_MAPPER
  },
  [ISSUE_TYPE_AFTER_SALE_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_AFTER_SALE_MAPPER,
    clientStatuses: CLIENT_STATUSES_REF_AFTER_SALE_MAPPER,
    issueStatuses: ISSUE_STATUS_MAPPER
  },
  [ISSUE_TYPE_TRANSPORT_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_TRANSPORT_MAPPER
  }
}

export function getConfigForIssueType (issueTypeId: IssueTypeIdTypes) {
  return ISSUE_CONFIG[issueTypeId] || {}
}

export const BUSINESS_STATUS_MAPPER: BusinessStatusMapper = {
  [BUSINESS_STATUS_REQUESTED_ID]: BUSINESS_STATUS_REQUESTED_TEXT,
  [BUSINESS_STATUS_LABELLED_ID]: BUSINESS_STATUS_LABELLED_TEXT,
  [BUSINESS_STATUS_RECEIVED_ID]: BUSINESS_STATUS_RECEIVED_TEXT,
  [BUSINESS_STATUS_SENT_ID]: BUSINESS_STATUS_SENT_TEXT,
  [BUSINESS_STATUS_DELIVERED_ID]: BUSINESS_STATUS_DELIVERED_TEXT,
  [BUSINESS_STATUS_CANCELLED_ID]: BUSINESS_STATUS_CANCELLED_TEXT
}

export const ESTIMATED_COSTS_STATUS_MAPPER: EstimatedCostsStatusMapper = {
  [ADVERTISEMENT_STATUS_ID]: ADVERTISEMENT_STATUS_TEXT,
  [SENT_BUDGE_STATUS_ID]: SENT_BUDGE_STATUS_TEXT,
  [ACEPTED_STATUS_ID]: ACEPTED_STATUS_TEXT,
  [CANCELLED_STATUS_ID]: CANCELLED_STATUS_TEXT
}
