import { IResourceComponentsProps, useSelect, useTranslate } from '@pankod/refine-core'

import {
  List,
  Table,
  DateField,
  Tabs,
  CreateButton,
  useDrawerForm,
  ShowButton,
  Tag,
  FilterDropdownProps,
  FilterDropdown,
  Select,
  Input,
  Space,
  Checkbox
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, ISSUE_TYPE_IDS, capitalizeFirstLetter, filter, getSupaData, sortAlphabetically, sorter, supabaseClient } from 'utility'
import { CLIENT_STATUSES_MAPPER, ISSUE_SUB_TYPES_MAPPER, ISSUE_TYPES_MAPPER, ISSUE_STATUS_MAPPER, getConfigForIssueType } from 'utility/mapper'
import { ClientStatusIdTypes, IssueSubTypeIdTypes, IssueTypeIdTypes, IssueStatusIdTypes } from 'types/types'
import { IssueDrawer } from './drawer'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { useEffect, useState } from 'react'
import { tablePropsProps } from 'pages/ordersLive/ordersLiveList'
import { DateFilterDropdown } from 'components/filterDropdown'

type Issues = definitions['issues'] & { 'order_id': definitions['orders'] }

const IssueTable: React.FC<IResourceComponentsProps & { issueTypeId: IssueTypeIdTypes }> = ({ issueTypeId }) => {
  let callingServer = false

  const permanentFilter: filter[] = [
    { field: 'deleted', operator: 'eq', value: false },
    { field: 'issue_type_id', operator: 'eq', value: issueTypeId }
  ]

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [filters, setFilters] = useState<filter[]>([...permanentFilter])
  const [sorter, setSorter] = useState<sorter>({ field: 'id', ascending: false })
  const [totalData, setTotalData] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(localStorage.getItem('issuesPage') ? parseInt(localStorage.getItem('issuesPage') || '1') : 1)
  const [pageSize, setPageSize] = useState(localStorage.getItem('issuesPageSize') ? parseInt(localStorage.getItem('issuesPageSize') || '10') : 10)
  const [loading, setLoading] = useState(false)

  const [shipperFilter, setShipperFilter] = useState<String>('')
  const [shippingMethodFilter, setShippingMethodFilter] = useState<String>('')
  const [trackingIDFilter, setTrackingIDFilter] = useState<String>('')

  const getIssuesList = async (resetPages: boolean = false) => {
    if (!callingServer) {
      setLoading(true)
      callingServer = true
      const cPage = (resetPages) ? 1 : currentPage
      const cPageSize = pageSize

      const { data, count } = await getSupaData('issues', '*, order_id!inner(id, items(name), status!inner(id, name))', cPage, cPageSize, filters, sorter)

      const tableProps = {
        dataSource: data
      }
      setTableProps(tableProps)
      setTotalData(count || 0)
      setLoading(false)
    }
    setLoading(false)
    callingServer = false
  }

  useEffect(() => {
    // console.log('filters -->', filters)
    if (!callingServer) {
      getIssuesList()
    }
  }, [filters])

  useEffect(() => {
    // console.log('sorter -->', sorter)
    if (!callingServer) {
      if (tableProps.dataSource.length > 0) {
        getIssuesList()
      }
    }
  }, [sorter, currentPage, pageSize])

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    show: createShow,
    close
  } = useDrawerForm<Issues>({
    action: 'create',
    resource: 'issues',
    redirect: false
  })

  const { options: statusOptions } = useSelect<definitions['status']>({
    resource: 'status',
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const { t } = useTranslation()

  const handleCompletedChange = async (value: any, record: any, func?: Function) => {
    if (record.id) {
      await supabaseClient
        .from('issues')
        .update({
          is_completed: value
        })
        .eq('id', record.id)
    }
  }

  function dataToExport (issues: Issues[]) {
    return issues.map((issue) => {
      const involvedProducts = (issue.order_id as any).items?.map((item: { name: string }) => item.name)?.join(' | ') || ''
      const issueType = t(`issues.issue_types.${ISSUE_TYPES_MAPPER[issue.issue_type_id as IssueTypeIdTypes]}`)
      const issueSubTypeId = issue.issue_sub_type_id ? t(`issues.issue_sub_types.${issue.issue_sub_type_id ? ISSUE_SUB_TYPES_MAPPER[issue.issue_sub_type_id as IssueSubTypeIdTypes] : ''}`) : ''
      const clientStatus = issue.client_status_id ? t(`issues.client_statuses.${issue.client_status_id ? CLIENT_STATUSES_MAPPER[issue.client_status_id as ClientStatusIdTypes] : ''}`) : ''
      const issueStatus = issue.issue_status_id ? t(`issues.issue_statuses.${issue.issue_status_id ? ISSUE_STATUS_MAPPER[issue.issue_status_id as IssueStatusIdTypes] : ''}`) : ''

      return {
        [t('issues.fields.id')]: issue.id || '',
        [t('issues.fields.order_id')]: issue.order_id || '',
        [t('issues.fields.involved_products')]: involvedProducts,
        [t('issues.fields.issue_type_id')]: issueType,
        [t('issues.fields.issue_sub_type_id')]: issueSubTypeId,
        [t('issues.fields.client_status_id')]: clientStatus,
        [t('issues.fields.issue_status_id')]: issueStatus,
        [t('issues.fields.reason')]: issue.reason || '',
        [t('issues.fields.notes')]: issue.notes || '',
        [t('issues.fields.notes_marketplace')]: issue.notes_marketplace || '',
        [t('issues.fields.request_date')]: issue.request_date ? new Date(issue.request_date) : '',
        [t('issues.fields.shipping_method')]: issue.shipping_method || '',
        [t('issues.fields.shipper')]: issue.shipper || '',
        [t('issues.fields.tracking_id')]: issue.tracking_id || '',
        [t('issues.fields.issue_deadline')]: issue.issue_deadline ? new Date(issue.issue_deadline) : '',
        [t('issues.fields.delivery_deadline')]: issue.delivery_deadline ? new Date(issue.delivery_deadline) : '',
        [t('issues.fields.created_at')]: new Date(issue.created_at) || ''
      }
    })
  }

  async function exportIssues () {
    return dataToExport(tableProps.dataSource || [])
  }

  const { issueSubTypes, clientStatuses, issueStatuses } = getConfigForIssueType(issueTypeId)
  return (
    <List
      headerProps={{
        extra: <>
          <CreateButton onClick={() => createShow()} />
          <ExportToExcelButton
            filename={`${t('issues.titles.list')}-${t(`issues.issue_types.${ISSUE_TYPES_MAPPER[issueTypeId]}`)}`}
            asyncFunction={exportIssues}
          />
        </>
      }}
      title={t('issues.titles.list')}
    >
      <Table
        {...tableProps}
        scroll={{ x: '100%', y: '60vh' }}
        rowKey="id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalData,
          onChange (page, pageSize) {
            setCurrentPage(page)
            setPageSize(pageSize)
            localStorage.setItem('issuesPage', page.toString() || '')
            localStorage.setItem('issuesPageSize', pageSize.toString() || '')
          }
        }}
        onChange={(_pagination: any, _filters: any, sorter: any, _extra: any) => {
          if (sorter && sorter.order) {
            setSorter({ field: sorter.field, ascending: (sorter.order === 'ascend') })
          }
          if (_filters) {
            const nextFilter = [...permanentFilter]
            Object.entries(_filters).forEach((entry: any) => {
              if (entry[1] !== null) {
                // nextFilter.push({})
                if (Array.isArray(entry[1])) {
                  nextFilter.push({ field: entry[0], operator: 'in', value: entry[1] })
                } else if (typeof entry[1] === 'string') {
                  nextFilter.push({ field: entry[0], operator: 'eq', value: entry[1] })
                }
              }
            })
            setFilters([...nextFilter])
          }
        }}
      >
        <Table.Column
          key="id"
          dataIndex="id"
          title="ID"
          width={100}
          align='center'
          sorter
        />
        <Table.Column<Issues>
          key="is_opened"
          dataIndex="is_opened"
          align='center'
          title={t('issues.fields.status')}
          sorter
          width={150}
          render={(value: boolean) => (
            <Tag color={value ? 'red' : 'green'}>
              {t(`issues.issue_status.${value ? 'open' : 'close'}`)}
            </Tag>
          )}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
            >
              <Select
                placeholder={t('issues.fields.status')}
                options={[
                  { label: t('issues.issue_status.open'), value: 'true' },
                  { label: t('issues.issue_status.close'), value: 'false' }
                ]}
                style={{ minWidth: 100 }}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="order_id"
          align='center'
          dataIndex={['order_id', 'id']}
          title={t('issues.fields.order_id')}
          sorter
          width={150}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
            >
              <Select
                mode="multiple"
                placeholder={t('issues.fields.order_id')}
                showSearch
                defaultActiveFirstOption={false}
                showArrow={true}
                options={(tableProps.dataSource || []).map(issue => ({
                  value: issue.order_id.id.toString(),
                  label: issue.order_id.id.toString()
                }))}
                filterOption={false}
                style={{ minWidth: 160 }}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="order_id.status.id"
          dataIndex={['order_id', 'status', 'name']}
          title={t('issues.fields.syniva_status')}
          sorter
          width={150}
          render={(value) => capitalizeFirstLetter(value)}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
            >
              <Select
                placeholder={t('issues.fields.syniva_status')}
                options={statusOptions.map(status => ({
                  value: status.value.toString(),
                  label: capitalizeFirstLetter(status.label)
                }))}
                style={{ minWidth: 200 }}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="issue_sub_type_id"
          dataIndex="issue_sub_type_id"
          title={t('issues.fields.issue_sub_type_id')}
          sorter
          width={150}
          render={(value: IssueSubTypeIdTypes) => value ? t(`issues.issue_sub_types.${ISSUE_SUB_TYPES_MAPPER[value]}`) : ''}
          filterDropdown={(props: FilterDropdownProps) => {
            if (!issueSubTypes) return null

            return (
              <FilterDropdown
                {...props}
                clearFilters={() => {
                  props.clearFilters?.()
                  props.confirm()
                }}
              >
                <Select
                  placeholder={t('issues.fields.issue_sub_type_id')}
                  options={issueSubTypes
                    ? Object.entries(issueSubTypes)
                      .map(([key, value]) => ({
                        value: key,
                        label: t(`issues.issue_sub_types.${value}`)
                      }))
                      .sort((a, b) => sortAlphabetically(a.label, b.label))
                    : []
                  }
                  style={{ minWidth: 200 }}
                />
              </FilterDropdown>
            )
          }}
        />
        <Table.Column<Issues>
          key='is_completed'
          dataIndex="is_completed"
          align="center"
          title={t('issues.fields.is_completed')}
          width= {150}
          render={(value, record: Issues) => (
            <Space style={{ justifyContent: 'center' }}>
              <Checkbox defaultChecked={record.is_completed} onChange={(e: any) => handleCompletedChange(e.target.checked, record)} />
            </Space>
          )}
        />
        <Table.Column<Issues>
          key="client_status_id"
          dataIndex="client_status_id"
          title={t('issues.fields.client_status_id')}
          sorter
          width={150}
          render={(value: ClientStatusIdTypes) => value ? t(`issues.client_statuses.${CLIENT_STATUSES_MAPPER[value]}`) : ''}
          filterDropdown={(props: FilterDropdownProps) => {
            if (!clientStatuses) return null

            return (
              <FilterDropdown
                {...props}
                clearFilters={() => {
                  props.clearFilters?.()
                  props.confirm()
                }}
              >
                <Select
                  placeholder={t('issues.fields.client_status_id')}
                  options={clientStatuses
                    ? Object.entries(clientStatuses)
                      .map(([key, value]) => ({
                        value: key,
                        label: t(`issues.client_statuses.${value}`)
                      }))
                      .sort((a, b) => sortAlphabetically(a.label, b.label))
                    : []
                  }
                  style={{ minWidth: 200 }}
                />
              </FilterDropdown>
            )
          }}
        />
        <Table.Column<Issues>
          key="issue_status_id"
          dataIndex="issue_status_id"
          title={t('issues.fields.issue_status_id')}
          sorter
          width={200}
          render={(value: IssueStatusIdTypes) => value ? t(`issues.issue_statuses.${ISSUE_STATUS_MAPPER[value]}`) : ''}
          filterDropdown={(props: FilterDropdownProps) => {
            if (!issueStatuses) return null

            return (
              <FilterDropdown
                {...props}
                clearFilters={() => {
                  props.clearFilters?.()
                  props.confirm()
                }}
              >
                <Select
                  placeholder={t('issues.fields.issue_status_id')}
                  options={issueStatuses
                    ? Object.entries(issueStatuses)
                      .map(([key, value]) => ({
                        value: key,
                        label: t(`issues.issue_statuses.${value}`)
                      }))
                      .sort((a, b) => sortAlphabetically(a.label, b.label))
                    : []
                  }
                  style={{ minWidth: 200 }}
                />
              </FilterDropdown>
            )
          }}
        />
        <Table.Column<Issues>
          key="reason"
          dataIndex="reason"
          title={t('issues.fields.reason')}
          sorter
          width={200}
        />
        <Table.Column<Issues>
          key="notes"
          dataIndex="notes"
          title={t('issues.fields.notes')}
          sorter
          width={300}
          render={value => <span style={{ whiteSpace: 'pre-line' }}>{value}</span>}
        />
        <Table.Column<Issues>
          key="notes_marketplace"
          dataIndex="notes_marketplace"
          title={t('issues.fields.notes_marketplace')}
          sorter
          width={150}
          render={value => <span style={{ whiteSpace: 'pre-line' }}>{value}</span>}
        />
        <Table.Column<Issues>
          key="request_date"
          dataIndex="request_date"
          align="center"
          title={t('issues.fields.request_date')}
          render={(value) => value && <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
          sorter
          width={150}
        />
        <Table.Column<Issues>
          key="shipping_method"
          dataIndex="shipping_method"
          title={t('issues.fields.shipping_method')}
          sorter
          width={150}
          filtered={filters.find((f: filter) => f.field === 'shipping_method') !== undefined}
          filterDropdown={(props) => (
            <FilterDropdown {...props}
              mapValue={(selectedKeys) => {
                setShippingMethodFilter(selectedKeys.toString())
                return selectedKeys.toString()
              }}
              clearFilters={() => {
                setFilters([...filters.filter((x: filter) => x.field !== 'shipping_method')])
                setShippingMethodFilter('')
              }}
              confirm={() => {
                if (shippingMethodFilter) {
                  if (filters.find((x: filter) => x.field === 'shipping_method')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'shipping_method')
                    updatedFilters[index].value = shippingMethodFilter

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'shipping_method', operator: 'ilike', value: shippingMethodFilter }]])
                  }
                }
              }}
            >
              <Input type='text' />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="shipper"
          dataIndex="shipper"
          title={t('issues.fields.shipper')}
          sorter
          width={150}
          filtered={filters.find((f: filter) => f.field === 'shipper') !== undefined}
          filterDropdown={(props) => (
            <FilterDropdown {...props}
              mapValue={(selectedKeys) => {
                setShipperFilter(selectedKeys.toString())
                return selectedKeys.toString()
              }}
              clearFilters={() => {
                setFilters([...filters.filter((x: filter) => x.field !== 'shipper')])
                setShipperFilter('')
              }}
              confirm={() => {
                if (shipperFilter) {
                  if (filters.find((x: filter) => x.field === 'shipper')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'shipper')
                    updatedFilters[index].value = shipperFilter

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'shipper', operator: 'ilike', value: shipperFilter }]])
                  }
                }
              }}
            >
              <Input type='text' />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="tracking_id"
          dataIndex="tracking_id"
          title={t('issues.fields.tracking_id')}
          sorter
          width={150}
          filtered={filters.find((f: filter) => f.field === 'tracking_id') !== undefined}
          filterDropdown={(props) => (
            <FilterDropdown {...props}
              mapValue={(selectedKeys) => {
                setTrackingIDFilter(selectedKeys.toString())
                return selectedKeys.toString()
              }}
              clearFilters={() => {
                setFilters([...filters.filter((x: filter) => x.field !== 'tracking_id')])
                setTrackingIDFilter('')
              }}
              confirm={() => {
                if (trackingIDFilter) {
                  if (filters.find((x: filter) => x.field === 'tracking_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'tracking_id')
                    updatedFilters[index].value = trackingIDFilter

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'tracking_id', operator: 'ilike', value: trackingIDFilter }]])
                  }
                }
              }}
            >
              <Input type='text' />
            </FilterDropdown>
          )}
        />
        <Table.Column<Issues>
          key="issue_deadline"
          dataIndex="issue_deadline"
          align="center"
          title={t('issues.fields.issue_deadline')}
          render={(value) => value && <DateField format={DEFAULT_DATE_FORMAT} value={value}/>}
          sorter
          width={150}
          filterDropdown={() => (
            <DateFilterDropdown
              field='issue_deadline'
              filters={filters}
              setFilters={(newFilter: any, method: string) => {
                if (newFilter.length > 0 && method === 'merge') {
                  let filtersToAdd = [...filters]
                  if (filters.find((x: filter) => x.field === 'issue_deadline' && x.operator === 'gte')) {
                    const updatedFilters = [...filtersToAdd]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'issue_deadline' && x.operator === 'gte')
                    updatedFilters[index].value = newFilter[0].value

                    filtersToAdd = [...updatedFilters]
                  } else {
                    filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                  }

                  if (filters.find((x: filter) => x.field === 'issue_deadline' && x.operator === 'lte')) {
                    const updatedFilters = [...filtersToAdd]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'issue_deadline' && x.operator === 'lte')
                    updatedFilters[index].value = newFilter[1].value

                    filtersToAdd = [...updatedFilters]
                  } else {
                    filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                  }

                  setFilters([...filtersToAdd])
                } else {
                  setFilters([...filters.filter((x: filter) => x.field !== 'issue_deadline')])
                }
              }}
            />
          )}
        />
        <Table.Column<Issues>
          key="delivery_deadline"
          dataIndex="delivery_deadline"
          align="center"
          title={t('issues.fields.delivery_deadline')}
          render={(value) => value && <DateField format={DEFAULT_DATE_FORMAT} value={value}/>}
          sorter
          width={150}
        />
        <Table.Column<Issues>
          key="created_at"
          dataIndex="created_at"
          align="center"
          title={t('issues.fields.created_at')}
          render={(value) => value && <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
          sorter
          width={150}
        />
        <Table.Column<Issues>
          fixed="right"
          title={t('table.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          width={150}
          render={(_, record) => {
            return (
              <ShowButton
                resourceNameOrRouteName='issues'
                hideText
                style={{ color: 'green', fontSize: '15px' }}
                recordItemId={record.id}
              />
            )
          }}
        />
      </Table>

      <IssueDrawer
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        close={close}
        refetchFn={() => getIssuesList()}
        issueTypeId={issueTypeId}
      />
    </List>
  )
}

export const IssueList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  return (
    <Tabs defaultActiveKey="0" size='middle'>
      {ISSUE_TYPE_IDS.map((issueTypeId, index) => {
        return (
          <Tabs.TabPane tab={t(`issues.issue_types.${ISSUE_TYPES_MAPPER[issueTypeId]}`)} key={index}>
            <IssueTable issueTypeId={issueTypeId} />
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}
